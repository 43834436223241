import React from 'react';
import UserHeader from '../../components/common/UserHeader';
import { BiMailSend } from "react-icons/bi";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineShareLocation } from "react-icons/md";

const ContactUs = () => {
  return (
    <React.Fragment>
      <UserHeader pageName="Contact Us" mainText="Home" />

      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Contact Details Section */}
          <div className="grid grid-cols-1 gap-6">
            <div className="p-6 bg-white rounded-lg shadow-lg flex items-center">
              <BiMailSend size={44} className="text-primaryBtnColor mr-4" />
              <div>
                <h4 className="text-xl tracking-wider font-semibold text-primaryColor">Email Address</h4>
                <p className="text-sm text-secondaryTextColor tracking-wide mt-2">
                  araptirealestatepvtltd89@gmail.com
                </p>
              </div>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg flex items-center">
              <BiPhoneCall size={44} className="text-primaryBtnColor mr-4" />
              <div>
                <h4 className="text-xl tracking-wider font-semibold text-primaryColor">Phone Numbers</h4>
                <p className="text-sm text-secondaryTextColor tracking-wide mt-2">
                  +91 9827844414
                </p>
              </div>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg flex items-center">
              <MdOutlineShareLocation size={44} className="text-primaryBtnColor mr-4" />
              <div>
                <h4 className="text-xl tracking-wider font-semibold text-primaryColor">Office Address</h4>
                <p className="text-sm text-secondaryTextColor tracking-wide mt-2">
                  Plot No-508, Near DM Public school, Durgapurpatna, Basuaghai, Puri Bypass Road. Pin-751002
                </p>
              </div>
            </div>
          </div>

          {/* Google Map Section */}
          <div className="rounded-lg overflow-hidden">
            <iframe
              title="Google Map"
              className="w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d479831.5330889327!2d85.51608920442922!3d20.021779259714275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPlot%20No-508%2C%20Near%20DM%20Public%20school%2C%20Durgapurpatna%2C%20Basuaghai%2C%20Puri%20Bypass%20Road.%20Pin-751002!5e0!3m2!1sen!2sin!4v1725532374942!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div
        className="py-12 px-4 sm:px-6 lg:px-8 bg-cover bg-center bg-no-repeat mb-8"
        style={{ backgroundImage: `url('https://www.mandanibay.com/wp-content/uploads/2023/07/shutterstock_1700575657.jpg')` }}
      >
        <div className="max-w-4xl mx-auto px-8 pb-8 pt-6 rounded-lg shadow-lg backdrop-blur-md bg-white/60 border border-white/30">
          <h2 className="text-2xl font-bold text-primaryColor text-center">Drop Us A Line</h2>
          <div className='text-md tracking-wide text-secondaryTextColor mt-2 mb-4 text-center'>
            Your details will not be published.
          </div>
          <form className="space-y-4">
            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full sm:w-1/2 px-2 mb-4 sm:mb-0">
                <label htmlFor="name" className="sr-only">Name</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Your Name"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-white/80"
                  required
                />
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <label htmlFor="email" className="sr-only">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Your Email"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-white/80"
                  required
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full sm:w-1/2 px-2 mb-4 sm:mb-0">
                <label htmlFor="phone" className="sr-only">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="Your Phone"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-white/80"
                  required
                />
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <label htmlFor="property-type" className="sr-only">Property Type</label>
                <select
                  id="property-type"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-white/80"
                  required
                >
                  <option value="" disabled>Select Property Type</option>
                  <option value="residential">Residential</option>
                  <option value="commercial">Commercial</option>
                  <option value="land">Land</option>
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="message" className="sr-only">Message</label>
              <textarea
                id="message"
                placeholder="Your Message"
                rows="4"
                className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-white/80"
                required
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-primaryColor text-white p-3 rounded-lg hover:bg-primaryBtnColor transition duration-200"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
