import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEye } from "react-icons/fa";

const JobApplications = () => {
    const [applicationsData, setApplicationsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedResume, setSelectedResume] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://arapti.onrender.com/real-estate/jobs/get-all-applications`);
            // console.log(response.data);
            setApplicationsData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const applicationsPerPage = 5;
    const totalPages = Math.ceil(applicationsData.length / applicationsPerPage);

    const indexOfLastApplication = currentPage * applicationsPerPage;
    const indexOfFirstApplication = indexOfLastApplication - applicationsPerPage;
    const currentApplications = applicationsData.slice(indexOfFirstApplication, indexOfLastApplication);

    const handlePrevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };


    const openModal = (resumeUrl) => {
        setSelectedResume(resumeUrl);
    };

    const closeModal = () => {
        setSelectedResume(null);
    };


    const downloadResume = (resumeUrl) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = resumeUrl;
        downloadLink.setAttribute('download', 'Resume');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const SkeletonLoader = () => {
        return (
            <div className="bg-gray-200 animate-pulse rounded-lg">
                <div className="px-4 py-4 grid grid-cols-1 gap-6">
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                    <div className="w-full h-10 bg-gray-300 rounded"></div>
                </div>
            </div>
        );
    };


    return (
        <div className="container mx-auto px-10 py-5">
            <h2 className="text-2xl font-normal mb-4">Job Applications</h2>
            {loading ? (
                <>
                    <SkeletonLoader />
                </>
            ) : (
                <>
                    <div className="overflow-x-auto border rounded-t-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-blue-200">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Name</th>
                                    <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Email</th>
                                    <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Phone</th>
                                    <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Job Role</th>
                                    <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Job Type</th>
                                    <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">Resume</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {currentApplications.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-center">No data found.</td>
                                    </tr>
                                ) : (
                                    currentApplications.map((ele, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap">{ele?.name}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{ele?.email}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{ele?.phone}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{ele?.jobData?.role || 'null'}</td>
                                            <td className="px-6 py-4 whitespace-nowrap">{ele?.jobData?.type || 'null'}</td>
                                            <td className="px-6 py-4 whitespace-nowrap flex gap-2">
                                                <img src={ele?.resume} alt="Resume" className='w-6 h-6' />
                                                <button onClick={() => openModal(ele?.resume)}>
                                                    <FaEye color='teal' size={25} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex items-center justify-between p-4 border mt-4 rounded-b-lg">
                        <div className="flex items-center gap-4">
                            <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className={` ${currentPage === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-blue-500 hover:text-blue-600'}`}
                            >
                                Prev
                            </button>
                            <h1>{currentPage}</h1>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className={`${currentPage === totalPages ? 'text-gray-300 cursor-not-allowed' : 'text-blue-500 hover:text-blue-600'}`}
                            >
                                Next
                            </button>
                        </div>
                        <p className="text-sm text-gray-500">Page {currentPage} of {totalPages}</p>
                    </div>
                </>
            )}

            {selectedResume && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded-lg xl:w-[50%] lg:w-[70%] md:w-[85%] w-[90%] md:h-[95vh] h-[80vh] overflow-scroll">
                        <img src={selectedResume} alt="Resume" className='h-full w-full' />
                        <div className="flex justify-between mt-4">
                            <button onClick={downloadResume} className='bg-blue-500 text-white px-4 py-1 rounded-md'>Download</button>
                            <button onClick={closeModal} className='bg-blue-500 text-white px-4 py-1 rounded-md'>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default JobApplications;
