import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/logos/mainLogo.png';
import forgot from '../../assets/login/loginpage.webp';
import './nonAuth.css';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [changedMsg, setChangedMsg] = useState('');

    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!phoneNo) {
            isValid = false;
            errors.phoneNo = 'Phone number is required';
        } else if (!/^\d{10}$/.test(phoneNo)) {
            isValid = false;
            errors.phoneNo = 'Invalid phone number, must be 10 digits';
        }

        if (!email) {
            isValid = false;
            errors.email = 'Email is required';
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
            isValid = false;
            errors.email = 'Invalid email format';
        }

        if (!password) {
            isValid = false;
            errors.password = 'Password is required';
        } else if (password.length < 6) {
            isValid = false;
            errors.password = 'Password length min 6 character';
        }

        if (!confirmPassword) {
            isValid = false;
            errors.confirmPassword = 'Confirm Password is required';
        }

        if (password !== confirmPassword) {
            isValid = false;
            errors.confirmPassword = 'Passwords do not match.';
        }

        setErrors(errors);
        return isValid;
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        let data = JSON.stringify({
            "email": email,
            "phoneNo": phoneNo,
            "newPassword": password
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://arapti.onrender.com/real-estate/admin/forgot-password`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        setLoading(true);
        axios.request(config)
            .then((response) => {
                // console.log(response.data);
                // setChangedMsg(response?.data?.message);
                setLoading(false);
                navigate("/login");
            })
            .catch((error) => {
                // console.log(error?.response?.data?.message);
                setLoading(false);
                setChangedMsg(error?.response?.data?.message);
            });
    };

    return (
        <React.Fragment>
            <div className="flex w-full min-h-screen overflow-hidden">
                <div className="w-full lg:w-1/2 flex items-center justify-center overflow-auto py-8" style={{ maxHeight: '100vh' }}> {/* Added padding here */}
                    <div className="px-4 sm:px-0 w-full max-w-sm">
                        <div className="flex items-center justify-center gap-1 mt-16">
                            <img src={logo} alt="logo" className='w-48 h-auto' />
                        </div>

                        <div className='my-6 text-center text-lg text-gray-700 font-semibold tracking-wider'>
                            Recover Your Account Password
                        </div>

                        {changedMsg && <div className="mb-4 text-center text-red-500 text-sm tracking-wider">
                            {changedMsg}
                        </div>}

                        <div className="mx-auto">
                            <form
                                onSubmit={handleForgotPassword}
                                className="grid space-y-3">
                                <input
                                    type="email"
                                    className="h-10 rounded-md px-3 py-6 border text-lg"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && <div className="text-red-500 text-sm tracking-wider">
                                    {errors.email}
                                </div>}

                                <input
                                    type="text"
                                    className="h-10 rounded-md px-3 py-6 border text-lg"
                                    placeholder="Phone Number"
                                    value={phoneNo}
                                    onChange={(e) => setPhoneNo(e.target.value)}
                                />
                                {errors.phoneNo && <div className="text-red-500 text-sm tracking-wider">
                                    {errors.phoneNo}
                                </div>}

                                <input
                                    type="password"
                                    className="h-10 rounded-md px-3 py-6 border text-lg"
                                    placeholder="New Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors.password && <div className="text-red-500 text-sm tracking-wider">
                                    {errors.password}
                                </div>}

                                <input
                                    type="password"
                                    className="h-10 rounded-md px-3 py-6 border text-lg"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                {errors.confirmPassword && <div className="text-red-500 text-sm tracking-wider">
                                    {errors.confirmPassword}
                                </div>}

                                <div className='flex items-center justify-between'>
                                    <div
                                        onClick={() => {
                                            navigate("/");
                                            window.scrollTo(0, 0);
                                        }}
                                        className='cursor-pointer relative'
                                    >
                                        <button>Home</button>
                                        {/* <span className="border border-primaryColor tooltip-text absolute invisible bg-gray-200 text-green-700 tracking-wide text-center text-sm py-2 px-4 rounded bottom-full left-1/2 transform -translate-x-1/2 z-10">
                                            Home
                                        </span> */}
                                    </div>

                                    <div
                                        onClick={() => navigate("/login")}
                                        className='cursor-pointer relative'
                                    >
                                        <button>Login</button>
                                        {/* <span className="border border-primaryColor tooltip-text absolute invisible bg-gray-200 text-green-700 tracking-wide text-center text-sm py-2 px-4 rounded bottom-full left-1/2 transform -translate-x-1/2 z-10">
                                            Login
                                        </span> */}
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="h-11 tracking-wider font-medium bg-primaryBtnColor hover:bg-primaryBtnHoverColor text-gray-100 text-lg rounded-md transition ease-in-out hover:duration-300">
                                    {loading ? "Please Wait.." : "Submit"}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="hidden lg:flex justify-center items-center w-1/2 bg-gradient-to-r from-[#F638B6] to-[#8c0b61]">
                    <img src={forgot} alt="loginImg" className='w-[85%] h-auto' />
                </div>
            </div>
        </React.Fragment>
    );
};
export default ForgotPassword;