import React, { useEffect } from 'react';
import Newbuild from "../../components/Home/Newbuild";
import PropertyCategories from '../../components/Home/PropertyCategories';
import TrendingProperties from '../../components/Home/TrendingProperties';
import Ourlands from '../../components/Home/Ourlands';
import PropertyDetailsAndForm from '../../components/Home/PropertyDetailsAndForm';
import TestimonialsSection from '../../components/Home/TestimonialsSection';
// import Location from '../../components/Home/Location';
// import Blogs from '../../components/Home/Blogs';
import Carousel from '../../components/Home/Carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: 'ease-in-out', 
          once: false, 
          mirror: true,
        });
      }, []);
      
    return (
        <div className='overflow-hidden'>
            <Carousel />
            <Newbuild />
            <PropertyCategories />
            <TrendingProperties />
            <Ourlands />
            {/* <Location /> */}
            <PropertyDetailsAndForm />
            <TestimonialsSection />
            {/* <Blogs /> */}
        </div>
    );
};
export default Home;