import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../../assets/home/freedback1.jpg';
import img2 from '../../assets/home/freedback2.jpg'
const TestimonialsSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out', 
      once: true,
    });
  }, []);

  const testimonials = [
    {
      name: "Chinmaya Kumar",
      title: "CEO & Founder",
      feedback:
        "The team at Arapti Real Estate was incredibly helpful throughout the property buying process. They were knowledgeable and responsive, making the experience smooth and stress-free.",
      image: img1, 
    },
    {
      name: "Aditya Shankar",
      title: "CEO & Founder",
      feedback:
        "The property matches all the specifications mentioned, and I'm very happy with the quality,Communication was clear and timely. I was kept informed throughout the buying process.",
      image: img2, 
    },
  ];

  return (
    <React.Fragment>
      <div className="bg-gray-100 py-12">
        <div className="text-center mb-10" data-aos="fade-up">
          <h4 className="text-blue-500 font-medium">TRUSTED REAL ESTATE AGENCY</h4>
          <h2 className="text-3xl font-bold mt-2">Find Client's Feedback</h2>
        </div>

        <div className="relative max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {testimonials.map((testimonial, index) => (
              <div 
                key={index} 
                className="bg-white p-10 rounded-lg shadow-md relative" 
                data-aos="zoom-in"
              >
                {/* Testimonial Content */}
                <div className="flex items-center mb-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <h4 className="text-lg font-semibold">
                      {testimonial.name}
                    </h4>
                    <p className="text-blue-500">{testimonial.title}</p>
                  </div>
                </div>
                <p className="text-gray-700">{testimonial.feedback}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TestimonialsSection;
