import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from './Card';
import { FaHome } from "react-icons/fa";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const TrendingProperties = () => {
    const navigate = useNavigate();
    const [properties, setProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllProperties();
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
            mirror: true,
        });
    }, []);

    const getAllProperties = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://arapti.onrender.com/real-estate/buildings/get-all-buildings`,
            headers: {},
            data: ''
        };

        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                setProperties(response?.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <div className="px-4 sm:px-6 md:px-10 w-full m-auto my-8">
                <div className='flex flex-col items-center justify-center gap-y-4 sm:gap-y-6 md:gap-y-8' data-aos="fade-up">
                    <p className="text-[15px] text-primaryColor sm:text-[16px] md:text-[17px] flex items-center space-x-3">
                        <FaHome />
                        <span>TRUSTED REAL ESTATE CARE</span>
                    </p>
                    <h1 className="text-primaryTextColor text-[30px] sm:text-[35px] md:text-[45px] font-bold mb-2 text-center">
                        Our Buildings
                    </h1>
                </div>

                {/* Property Cards Grid */}
                <div
                    className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 w-full mt-6 sm:mt-8 md:mt-10"
                    data-aos="fade-up"
                >
                    {isLoading ? (
                        // Tailwind Skeleton Loader
                        Array(4).fill().map((_, index) => (
                            <div key={index} className="w-full p-4 animate-pulse">
                                {/* Skeleton for Image */}
                                <div className="h-48 bg-gray-300 rounded-lg mb-4"></div>

                                {/* Skeleton for Title */}
                                <div className="h-6 bg-gray-300 rounded-md mb-2 w-3/4"></div>

                                {/* Skeleton for Subtitle */}
                                <div className="h-4 bg-gray-300 rounded-md w-1/2"></div>
                            </div>
                        ))
                    ) : (
                        properties?.slice(0, 4).map((property, index) => (
                            <Card
                                key={index}
                                property={property}
                                data-aos="fade-up"
                                data-aos-delay={index * 100}
                            />
                        ))
                    )}
                </div>

                {/* View More Button */}
                <div className="flex justify-center mt-6 sm:mt-8">
                    <button
                        onClick={() => navigate('/properties')}
                        className="bg-primaryColor text-white py-2 px-4 sm:py-3 sm:px-6 rounded-lg hover:bg-blue-700 flex items-center">
                        View More
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TrendingProperties;
















// import React, { useEffect, useState } from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import Card from './Card';
// import { FaHome } from "react-icons/fa";
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// const TrendingProperties = () => {
//     const navigate = useNavigate();
//     const [properties, setProperties] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);

//     useEffect(() => {
//         getAllProperties();
//         AOS.init({
//             duration: 1000,
//             easing: 'ease-in-out',
//             once: false,
//             mirror: true,
//         });
//     }, []);

//     const getAllProperties = () => {
//         let config = {
//             method: 'get',
//             maxBodyLength: Infinity,
//             url: `https://arapti.onrender.com/real-estate/buildings/get-all-buildings`,
//             headers: {},
//             data: ''
//         };

//         setIsLoading(true);
//         axios.request(config)
//             .then((response) => {
//                 // console.log("response", response?.data);
//                 setProperties(response?.data);
//                 setIsLoading(false);
//             })
//             .catch((error) => {
//                 console.log(error);
//                 setIsLoading(false);
//             });
//     }

//     return (
//         <React.Fragment>
//             <div className="px-4 sm:px-6 md:px-10 w-full m-auto my-8">
//                 <div className='flex flex-col items-center justify-center gap-y-4 sm:gap-y-6 md:gap-y-8' data-aos="fade-up">
//                     <p className="text-[15px] text-primaryColor sm:text-[16px] md:text-[17px] flex items-center space-x-3">
//                         <FaHome />
//                         <span>TRUSTED REAL ESTATE CARE</span>
//                     </p>
//                     <h1 className="text-primaryTextColor text-[30px] sm:text-[35px] md:text-[45px] font-bold mb-2 text-center">
//                         Trending Properties
//                     </h1>
//                 </div>

//                 {/* Property Cards Grid */}
//                 <div
//                     className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 w-full mt-6 sm:mt-8 md:mt-10"
//                     data-aos="fade-up"
//                 >
//                     {properties?.slice(0, 4).map((property, index) => (
//                         <Card
//                             key={index}
//                             property={property}
//                             data-aos="fade-up"
//                             data-aos-delay={index * 100}
//                         />
//                     ))}
//                 </div>

//                 {/* <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 w-full mt-6 sm:mt-8 md:mt-10" data-aos="fade-up">
//                     {properties?.map((property, index) => (
//                         <Card
//                             key={index}
//                             property={property}
//                             data-aos="fade-up"
//                             data-aos-delay={index * 100}
//                         />
//                     ))}
//                 </div> */}

//                 {/* View More Button */}
//                 <div className="flex justify-center mt-6 sm:mt-8">
//                     <button className="bg-primaryColor text-white py-2 px-4 sm:py-3 sm:px-6 rounded-lg hover:bg-blue-700 flex items-center" onClick={() => { navigate('/properties') }}>
//                         View More
//                         <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             fill="none"
//                             viewBox="0 0 24 24"
//                             strokeWidth={2}
//                             stroke="currentColor"
//                             className="w-4 h-4 sm:w-5 sm:h-5 ml-2"
//                         >
//                             <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
//                         </svg>
//                     </button>
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// }

// export default TrendingProperties;
