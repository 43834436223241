import axios from 'axios';
import React, { useEffect, useState } from 'react';
import bgprofile from '../../assets/profilebackgrounimg.jpg';

const Profile = () => {
    const [profileData, setProfileData] = useState({});
    const userData = JSON.parse(localStorage.getItem("adminInfo"));

    const profile = 'https://res.cloudinary.com/dhhodju5j/image/upload/v1725949799/WhatsApp_Image_2024-09-10_at_11.44.03_AM_x9gpyp.jpg';

    useEffect(() => {
        findAdmin()
    }, [])

    const findAdmin = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://arapti.onrender.com/real-estate/admin/get-all-admins`,
            headers: {},
        };

        axios.request(config)
            .then((response) => {
                setProfileData(response?.data?.find(ele => ele?._id === userData?._id))
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <div className='bg-white overflow-hidden'>
            <section className="w-full -z-10">
                <div className="flex flex-col">
                    <img
                        src={bgprofile}
                        alt='bg-img'
                        className="w-full xl:h-[20rem] lg:h-[18rem] md:h-[16rem] sm:h-[14rem] xs:h-[11rem]" />
                    <div className="sm:w-[80%] xs:w-[90%] mx-auto flex md:flex-row flex-col">
                        <img
                            src={profile}
                            alt='admin-img'
                            className="rounded-md lg:w-[12rem] lg:h-[12rem] md:w-[10rem] md:h-[10rem] sm:w-[8rem] sm:h-[8rem] xs:w-[7rem] xs:h-[7rem] outline outline-2 outline-offset-2 outline-blue-500 relative lg:bottom-[5rem] sm:bottom-[4rem] xs:bottom-[3rem]" />
                        <div>
                            <h1 className="w-full font-bold text-left my-4 sm:mx-4 xs:pl-4 text-black md:text-2xl xs:text-xl tracking-wider">
                                {profileData?.name}
                            </h1>

                            <div className="w-full text-left font-semibold sm:mx-4 xs:pl-4 text-primaryBtnColor text-md tracking-wide">
                                - Admin -
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 items-center relative lg:-top-8 md:-top-6 sm:-top-4 xs:-top-4 w-[90%] m-auto">
                        <p className="w-fit text-lg text-gray-400 font-semibold text-justify tracking-wide">
                            Arapti offers a seamless avenue for establishing a cutting-edge investment platform without necessitating coding expertise within a short time.
                        </p>

                        <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                            <div className="w-full flex sm:flex-row xs:flex-col gap-2 justify-center">
                                <div className="w-full">
                                    <dl className="text-black divide-y divide-gray-200">
                                        <div className="flex flex-col pb-3">
                                            <dt className="mb-1 text-gray-500 md:text-lg">Name</dt>
                                            <dd className="text-lg font-semibold">{profileData?.name}</dd>
                                        </div>
                                        {/* More <dt> and <dd> pairs */}
                                    </dl>
                                </div>
                                <div className="w-full">
                                    <dl className="text-black divide-y divide-gray-200">
                                        <div className="flex flex-col pb-3">
                                            <dt className="mb-1 text-gray-500 md:text-lg">Location</dt>
                                            <dd className="text-lg font-semibold">{profileData?.address}</dd>
                                        </div>
                                        {/* More <dt> and <dd> pairs */}
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
};

export default Profile;
