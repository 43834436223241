import React, { useEffect } from 'react';
import UserNavbar from '../../components/common/UserNavbar';
import { Navigate, useLocation } from 'react-router-dom';
import UserFooter from '../../components/common/UserFooter';
import { FaWhatsapp } from "react-icons/fa6";

const UserLayout = (props) => {
  if (localStorage.getItem('adminInfo')) {
    return (
      <Navigate to={{ pathname: '/dashboard' }} />
    );
  }

  const MessageButton = () => {
    const openMessageModal = () => {
      const phoneNumber = '9198278 44414';
      const whatsappUrl = `https://wa.me/${phoneNumber}`;
      window.open(whatsappUrl, "_blank");
    };

    return (
      <button
        className="fixed right-4 bottom-4 p-2 border border-gray-400 shadow-md shadow-gray-900 bg-[#f5e7e7] rounded-full hover:bg-[#baf4c3] hover:scale-105 ease-in-out duration-300 focus:outline-none transition-transform transform-gpu"
        onClick={openMessageModal}
      >
        <FaWhatsapp size={42} color='#10b416' />
      </button>
    );
  };

  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, [pathname]);
  
    return null;
  };

  return (
    <React.Fragment>
      <UserNavbar />
      <ScrollToTop/>
      {props.children}
      <UserFooter />
      <MessageButton />
    </React.Fragment>
  )
}

export default UserLayout
