import React, { useEffect } from "react";
import UserHeader from "../../../components/common/UserHeader";
import group1 from "../../../assets/property-selling.png";
import { FaArrowRight } from "react-icons/fa";
import { PropertySelling } from "./OurTopServices";
import { useNavigate } from "react-router-dom";

const PropertySellingService = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <UserHeader pageName="Service Detail" mainText="Home" />
      <div className="p-10">
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div>
            <h1 className="lg:text-[40px] text-lg text-[#212121] font-semibold tracking-wide lg:leading-10 lg:w-[80%]">
              PROPERTY SELLING SERVICE
            </h1>
          </div>
          <div>
            <img src={group1} alt="group1" />
          </div>
        </div>

        <div className="flex text-[16px] text-[#8B8989] tracking-wider mt-4 text-justify">
          Our selling services are designed to help you sell your property
          quickly and at the best possible price. Here's how we can assist you:
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Property Valuation: :
            </span>{" "}
            We understand that pricing your property correctly is crucial to
            attracting the right buyers. Our team will conduct a thorough
            evaluation of your property's value based on various factors such as
            location, size, condition, and market trends. With our accurate
            valuation, you can confidently set the best listing price to
            maximize your returns.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">Marketing: :</span>{" "}
            To ensure maximum exposure for your property, we'll craft a tailored
            marketing strategy that highlights its unique features and appeals
            to potential buyers. From professional photography and compelling
            listing descriptions to targeted online and offline marketing
            campaigns, we'll showcase your property to a wide audience of
            qualified buyers.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Property Showings: :
            </span>{" "}
            We'll handle all aspects of property showings, including scheduling
            appointments, coordinating with potential buyers, and conducting
            guided tours of your property. Our experienced agents will showcase
            your property in the best possible light, highlighting its key
            selling points and answering any questions from interested buyers.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">Negotiation :</span>{" "}
            Our skilled negotiators will advocate on your behalf throughout the
            negotiation process to secure the best possible price and terms for
            your property. We'll leverage our market expertise and negotiation
            skills to navigate offers, counteroffers, and any contingencies,
            ensuring that your interests are protected every step of the way.
            With our comprehensive selling services, you can trust Arapti to
            guide you through the selling process with professionalism,
            integrity, and personalized attention. Contact us today to get
            started and let us help you achieve your selling goals.Renting
            Services:
          </p>
        </div>

        <div className="mt-4">
          <h1 className="lg:text-[40px] text-2xl text-[#212121] font-semibold tracking-wide w-[80%]">
            Related Services
          </h1>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1  justify-between gap-8 mt-4">
          {PropertySelling?.map((ele, index) => (
            <div
              key={index}
              onClick={() => {
                navigate(ele?.path);
                window.scrollTo(0, 0);
              }}
              className="relative border rounded-xl overflow-hidden cursor-pointer group"
            >
              <img
                className="m-auto h-[250px] w-full"
                src={ele?.image}
                alt={ele?.title + " " + "Image"}
              />
              <div className="absolute inset-0 flex flex-col justify-between p-4 lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:-translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center">{ele?.title}</p>
                </div>
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center line-clamp-2">{ele?.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PropertySellingService;
