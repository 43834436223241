import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ProjectListTable = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [allProject, setAllProject] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const filteredProjects = allProject.filter((project) => {
    return (
      (selectedStatus === "All" || project.projectStatus === selectedStatus) &&
      project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const getAllProjects = () => {
    setLoad(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://arapti.onrender.com/real-estate/project/get_all_project",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setLoad(false);
        console.log("------>", response.data);
        setAllProject(response.data.projects);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  const deleteProjectById = (id) => {
    if (window.confirm("Are you sure, you want to delete this item ?")) {
      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `https://arapti.onrender.com/real-estate/project/delete_project_by_id/${id}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          toast.success("Project Deleted Successfully.");
          getAllProjects();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Couldn't delete the project !");
        });
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);
  console.log(filteredProjects);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

  const indexOfLastProject = currentPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="px-6 py-2 w-full">
        <input
          type="text"
          placeholder="Search by project name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 shadow-lg border rounded outline-none bg-white w-full"
        />
      </div>
      <div className="w-full px-6 py-2">
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="px-4 py-2 shadow-lg border rounded outline-none bg-white w-full"
        >
          <option value="All">All</option>

          <option value="Ongoing">Ongoing</option>
          <option value="Completed">Completed</option>
          <option value="Upcoming">Upcoming</option>
        </select>
      </div>
      <div className="overflow-x-auto border rounded-t-lg mx-4 my-4">
        <ToastContainer />
        <h2 className="text-2xl font-normal ml-6 mt-4 mb-4">Project Lists</h2>
        {load ? (
          <p colSpan="7" className="text-center tracking-wide  py-8">
            Loading...
          </p>
        ) : (
          <>
            <table className="min-w-full divide-y divide-gray-200">
              {/* Table Header */}
              <thead className="bg-blue-200">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                    S no.
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                    Project Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                    Price Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody className="bg-white divide-y divide-gray-200">
                {allProject?.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center py-4">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  currentProjects?.map((each, index) => (
                    <tr key={each._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {each?.projectName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {each?.projectType}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {each?.projectStatus}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {each?.location}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {each?.priceType}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-red-500 flex justify-center items-center gap-2">
                        <FaEdit
                          onClick={() =>
                            navigate(`/projectlists/edit/${each._id}`)
                          }
                          size={25}
                          color="#1172b9"
                          className="cursor-pointer"
                        />
                        <MdOutlineDeleteSweep
                          onClick={() => deleteProjectById(each?._id)}
                          size={30}
                          className="cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="flex items-center justify-between p-4 border mt-4 rounded-b-lg">
              <div className="flex items-center gap-4">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className={`${
                    currentPage === 1
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-blue-500 hover:text-blue-600"
                  }`}
                >
                  Prev
                </button>

                <h1>{currentPage}</h1>

                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-300 cursor-not-allowed"
                      : "text-blue-500 hover:text-blue-600"
                  }`}
                >
                  Next
                </button>
              </div>

              <p className="text-sm text-gray-500">
                Page {currentPage} of {totalPages}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ProjectListTable;
