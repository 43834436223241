import React, { useEffect, useState } from "react";
import ProjectsCard from "./ProjectsCard";
import axios from "axios";
const ProjectsContainer = () => {
  const [projectsData, setprojectsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [load, setLoad] = useState(false);
  const filteredProjects = projectsData.filter((project) => {
    return (
      (selectedStatus === "All" || project.projectStatus === selectedStatus) &&
      project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const getAllProjects = () => {
    setLoad(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://arapti.onrender.com/real-estate/project/get_all_project",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setLoad(false);
        console.log(response.data.projects);
        setprojectsData(response.data.projects);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getAllProjects();
  }, []);
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center md:px-12 mt-5 space-y-4 md:space-y-0">
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="All">All</option>
          <option value="Ongoing">Ongoing</option>
          <option value="Completed">Completed</option>
          <option value="Upcoming">Upcoming</option>
        </select>
        <div className="text-xl text-center font-bold tracking-wider text-primaryColor">
          Projects List
        </div>
        <input
          type="text"
          placeholder="Search by project name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="md:px-12 px-6 mt-10 mb-10 gap-7 flex flex-col">
        {load ? (
          <p className="text-center tracking-wide my-10">
            Loading all projects, Please wait...
          </p>
        ) : filteredProjects?.length === 0 ? (
          <p className="text-center">No Projects Available</p>
        ) : (
          filteredProjects?.map((data) => (
            <ProjectsCard key={data._id} data={data} />
          ))
        )}
      </div>
    </div>
  );
};

export default ProjectsContainer;
