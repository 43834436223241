import React, { useEffect, useState } from 'react';
import UserHeader from '../../../components/common/UserHeader';
import { TiDownload } from "react-icons/ti";
import { FaRegWindowClose } from "react-icons/fa";
import axios from 'axios';

const Gallery = () => {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
        const allImages = () => {
            let data = '';

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://arapti.onrender.com/real-estate/gallery/get-all-images`,
                headers: {},
                data: data
            };

            axios.request(config)
                .then((response) => {
                    // console.log(response.data);
                    setImages(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        allImages();
    }, [])

    const openModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setShowModal(false);
    };

    const downloadImage = async () => {
        try {
            const response = await fetch(selectedImage);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.jpg');
            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    return (
        <React.Fragment>
            <UserHeader
                pageName="Showcase Gallery"
                mainText="Home" />

            <div className='p-10'>
                {
                    images?.length < 1
                        ?
                        (
                            <div className="rounded-md tracking-wider w-full text-center p-4 border-2 border-primaryColor font-semibold text-md">
                                Loading ..
                            </div>
                        ) : (
                            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-4'>
                                {
                                    images?.map((ele, ind) => (
                                        <div
                                            key={ind}
                                            className='border p-2 bg-white rounded relative overflow-hidden'
                                        >
                                            <img
                                                className='h-[200px] w-[100%] transition-all duration-300 transform hover:scale-105'
                                                src={ele?.img_url} alt="images" onClick={() => openModal(ele?.img_url)}
                                            />
                                            <div
                                                className='text-center font-bold absolute top-0 left-0 w-full h-full flex flex-col
                                                           justify-center items-center opacity-0 bg-black bg-opacity-50 hover:opacity-100 
                                                           transition-opacity duration-300'>
                                                <h1 className='text-white mt-2 tracking-wider'>
                                                    {ele?.name}
                                                </h1>
                                                <button
                                                    className='tracking-wider text-sm font-medium text-white border border-primaryColor px-4 py-1 mt-2 rounded
                                                             hover:bg-primaryColor hover:text-white transition duration-150 ease-in-out'
                                                    onClick={() => openModal(ele?.img_url)}
                                                >
                                                    VIEW
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                }
            </div>
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden p-2">
                        <img src={selectedImage} alt="images" className="w-full" style={{ maxHeight: '80vh' }} />

                        <div className="flex justify-between items-center px-4 py-2">
                            <div>
                                <TiDownload
                                    onClick={downloadImage}
                                    size={28} />
                            </div>

                            <div>
                                <FaRegWindowClose
                                    onClick={closeModal}
                                    size={24} />
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Gallery;