import React from "react";
import UserHeader from "../../components/common/UserHeader";
import frame from "../../assets/frame.svg";

const TermsAndConditions = () => {
  return (
    <>
      <UserHeader
        headerImg={frame}
        pageName="Terms And Conditions"
        mainText="Home"
      />
      <div className="px-10 py-10 bg-gray-100 text-justify">
        <div className="">
          <h1 className="text-3xl font-bold mb-4">
            Arapti Real Estate Pvt. Ltd. Online Privacy Statement
          </h1>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            Overview
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            You take online privacy seriously and so does Arapti Real Estate
            Pvt. Ltd. This statement is one way of sustaining your trust in our
            company, our products, and our services. Our privacy statement
            describes how we collect, protect, and use information about you
            during your visit to our website.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            What Personal Information Does Arapti Real Estate Pvt. Ltd. Collect
            Online?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            We may collect personal information about you from the information
            you provide to us when you fill out an application or other forms on
            our site including: name, address, telephone number, and email
            address.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            Why Is Personal Information Collected?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            If you fill out an email or other form on our site, we may ask for a
            variety of information to help us communicate with you and to
            provide you with the best possible service.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            When Is Personal Information About Me Collected Online?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            We collect personal information about you when you fill out an email
            form on our site, when you register through registration web pages,
            and each time you update your personal profile.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            Does Arapti Real Estate Pvt. Ltd. Use Cookies or Other Online
            Technologies to Collect Information About Me?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            Some of our website features may make use of "cookie" technology to
            measure site activity and to customize information to your personal
            tastes. A cookie is an element of data that a website can send to
            your browser, which may then store the cookie on your hard drive.
            So, when you come back to visit us again, we can tailor information
            to suit your individual preferences. The goal is to save you time
            and provide you with a more meaningful visit.
          </p>
          <p className="mb-4 text-secondaryTextColor">
            When you visit our site, we may collect and store information about
            your visit on an anonymous, aggregate basis. We use this information
            to measure site activity and to develop ideas for improving our
            site. This information may include: time and length of your visit;
            the number of web pages you look at on our site; the website you
            visited just before coming to ours, and the name of your internet
            service provider.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            Does Arapti Real Estate Pvt. Ltd. Share Personal Information About
            Me?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            We may disclose information about you including your email address,
            contact info, and property request with an agent in our firm if you
            have requested to be contacted by an agent or to obtain information
            on an available property. Any email form generated from our site is
            sent to our Online Inquiry Manager. An email form regarding a
            company listing is sent directly to the Arapti listing agent, their
            Sales Manager, and the Online Inquiry Manager. We do not sell or
            distribute information about you to any third parties outside our
            company.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            How Can I Review, Change, or Correct Information You Collect?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            We are committed to maintaining accurate and up-to-date information
            on all of our customers. We provide you with access to your account
            information online at all times when you are logged onto our site.
            If you are a current customer and have registered for online access
            through our website, you are able to update information such as your
            address, telephone number, email address, user name, password, and
            secret question online. For assistance, please contact
            info@Arapti.com.
          </p>
          <p className="mb-4 text-secondaryTextColor">
            If you have completed and saved, but have not submitted, an online
            form or application you may be able to make certain changes to the
            information you have provided to us before it is submitted. If you
            are unable to make changes to the information you have provided to
            us online, you may contact us in order to do so. For assistance,
            please contact info@Arapti.com.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            How Do You Protect Personal Information?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            Email sent using this website's email forms is logged and reviewed.
            We restrict access to the information obtained from our website and
            web pages to our employees only, and to an Arapti Real Estate agent
            only if your inquiry requests assistance from an agent, such as a
            request for more information or to view a property. We maintain
            physical, electronic, and procedural safeguards designed to protect
            personal information.
          </p>
          <p className="mb-4 text-secondaryTextColor">
            Third-party vendors we engage to provide services on our behalf such
            as hosting, website development, and support that have access to
            Personal Information have agreed not to disclose the Personal
            Information or to use it for any purpose other than providing the
            requested services.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            Will Arapti Real Estate Pvt. Ltd. Make Changes To This Privacy
            Statement?
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            This statement is effective June 2024 and remains in effect until
            amended. Arapti Real Estate Pvt. Ltd. reserves the right to amend
            this Privacy Statement or alter its privacy principles at its sole
            discretion without notice to you. Any alterations to this Privacy
            Statement or our privacy principles will be posted on our website in
            a timely manner. By registering for or using the site, you signify
            your acceptance of this privacy statement. If you do not agree to
            this privacy statement, you cannot use the site.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            Real Estate Information
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            Arapti Real Estate Pvt. Ltd. strives to provide open and
            transparent information about real estate markets. For example, we
            make available past sales prices for homes, and allow any user to
            post a question or answer about a given home. Many times, this
            information is already publicly available. As long as the discussion
            surrounds real estate and not individuals, we intend to keep this
            information freely available through the Services. Where a
            professional participates in the Arapti Real Estate community, we
            may also allow users to comment on the professional, such as the
            lender ratings in the Arapti Real Estate Mortgage Marketplace.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            Your Rights
          </h2>
          <p className="mb-4 text-secondaryTextColor">
            If you are concerned about your data you have the right to request
            access to the personal data which we may hold or process about you.
            You have the right to require us to correct any inaccuracies in your
            data free of charge. At any stage, you also have the right to ask us
            to stop using your personal data for direct marketing purposes.
            Visit www.Arapti.com or email us at info@Arapti.com.
          </p>

          <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
            General
          </h2>
          <p className=" text-secondaryTextColor">
            We may amend this Privacy Policy at any time by posting the amended
            terms on this site.
          </p>
        </div>
      </div>
      <CopyrightPolicy />
      <ConfidentialityPolicy />
      <PrivacyPolicy />
      <DisclosurePolicy />
    </>
  );
};

export default TermsAndConditions;

const CopyrightPolicy = () => {
  return (
    <div className="px-10">
      <div>
        <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
          COPYRIGHT POLICY
        </h2>
        <p className="mb-4 text-secondaryTextColor">
          You may not post, distribute, or reproduce in any way any copyrighted
          material, trademarks, or other proprietary information without
          obtaining the prior written consent of the owner of such proprietary
          rights. If you believe that your work has been copied and posted on
          assavo.com in a way that constitutes copyright infringement,
          please provide our Copyright Agent with the following information:
        </p>
        <ul className="list-disc ml-8 mb-4">
          <li>
            An electronic or physical signature of the person authorized to act
            on behalf of the owner of the copyright interest.
          </li>
          <li>
            A description of the copyrighted work that you claim has been
            infringed.
          </li>
          <li>
            A description of where the material that you claim is infringing is
            located on our Site.
          </li>
          <li>Your address, telephone number, and email address.</li>
          <li>
            A written statement by you that you have a good faith belief that
            the disputed use is not authorized by the copyright owner, its
            agent, or the law.
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the above
            information in your Notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner's
            behalf.
          </li>
        </ul>
        <p className="mb-4 text-secondaryTextColor">
          assavo.com's Copyright Agent for Notice of claims of
          copyright infringement can be reached by writing to the address
          located under the Contact Us section on the site.
        </p>
      </div>
    </div>
  );
};

const ConfidentialityPolicy = () => {
  return (
    <div className="px-10">
      <div>
        <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
          CONFIDENTIALITY
        </h2>
        <p className="mb-4 text-secondaryTextColor">
          As assavo.com is an online platform, it cannot guarantee
          confidentiality of information provided by Users who use the Site, so
          any breach in privacy due to technical fault or any other means is not
          the responsibility of assavo.com.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          The User is entitled to access his own data and information stored in
          the database at assavo.com (subject to prior confirmation of
          identity) and may edit or amend such data and information at any time.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          Users need to be aware that when they voluntarily reveal
          identification-oriented information (name, email address) to anyone
          resulting in unsolicited messages from third parties, such interaction
          is beyond the control and liability of assavo.com.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          assavo.com may be liable to share all information available
          with it in response to any legal proceedings including but not
          restricted to court orders, notices, subpoena, FIR, etc.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          The copyright, know-how, or any other related intellectual property to
          the Service or assavo.com shall be the sole and exclusive
          property of the Company. In the event the User has contributed any
          content to assavo.com, the intellectual property of the same
          shall stand automatically assigned to the Company.
        </p>
      </div>
    </div>
  );
};

const PrivacyPolicy = () => {
  return (
    <div className="px-10">
      <div>
        <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
          PRIVACY POLICY
        </h2>
        <p className="mb-4 text-secondaryTextColor">
          The information provided by the user of assavo.com shall be
          in accordance with respective laws. The information/data provided by
          the user may be used by all divisions/sister concerns/ventures of
          assavo.com to carry out the purpose of the project, whereas
          in no case it shall be communicated/transformed/spread to any
          company/third party or whatsoever for any other commercial usage
          without the consent of the user.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          Updating/modification/deletion of user's data/information shall be
          done on the written request of the user himself or his representative
          or his authorized person only.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          By using this Site, the Users understand that all the information
          provided by you including and not limited to the company's
          information, property listing details are being submitted in the Site
          and by registering on this Site, you agree that the information posted
          by you is a public document that can be accessed by other Users of the
          Site.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          assavo.com is committed to respecting the online privacy of
          its Users and doesn't intend to sell, rent, or share the Personal
          Information of Users to Third Party. The Site shall not transfer any
          such information unless it is legally required or to provide services
          to support your search for candidates.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          assavo.com advises its Users to exercise complete caution
          while completing online transactions and it is the sole responsibility
          of the User to verify and validate the information present on the site
          as well as the people whom they choose to interact with.
          assavo.com shall not be responsible for any loss/damage due
          to negligence of the User.
        </p>
      </div>
    </div>
  );
};

const DisclosurePolicy = () => {
  return (
    <div className="px-10">
      <div>
        <h2 className="text-lg text-primaryColor font-semibold mt-6 mb-2">
          DISCLOSURE OF INFORMATION
        </h2>
        <p className="mb-4 text-secondaryTextColor">
          We collect information from you which may be disclosed and transferred
          to external service providers essential for providing services to us
          or directly to you. For instance, information may be shared with:
        </p>
        <ul className="list-disc ml-8 mb-4">
          <li>
            Affiliated Companies: For enhanced efficiency, relevance, innovative
            business matchmaking, and personalized services.
          </li>
          <li>
            Any Government, Enforcement, Regulatory, and Judicial Authorities:
            If disclosure of your Personal Information is necessary to respond
            to subpoenas, court orders, or legal process, or to establish or
            exercise our legal rights or defend against legal claims; or to
            investigate, prevent, or take action regarding illegal activities,
            suspected fraud, situations involving potential threats to the
            physical safety of any person, violations of Platform’s Terms &
            Conditions or as otherwise required by law.
          </li>
          <li>
            Service Providers: Including but not limited to payment, customer,
            and cloud computing service providers ("Third Party") engaged to
            facilitate user service requirements.
          </li>
          <li>
            Business Partners: For sending their business offers to users,
            solely owned and offered by them without site involvement. Links to
            their websites may be available on the site for user convenience,
            governed by their respective Privacy Policies.
          </li>
        </ul>
        <p className="mb-4 text-secondaryTextColor">
          Users of such websites are advised to read their privacy policies as
          our Privacy Policy does not apply. Contact us at the provided email
          address if you wish to object to any data processing purposes.
          However, kindly note that objecting or withdrawing consent may result
          in discontinuation of services through our site.
        </p>
        <p className="mb-4 text-secondaryTextColor">
          By further using and accessing our site, you have with your free,
          explicit, informed, and verifiable consent provided that you have
          agreed and accepted the clauses as mentioned above.
        </p>
      </div>
    </div>
  );
};
