import React, { useEffect, useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import care_icon from "../../assets/properties/care_icon.svg";
import image1 from "../../assets/properties/area_icon.svg";
import image2 from "../../assets/properties/unitIcon.svg";
import image3 from "../../assets/properties/towerIcon.svg";
import image4 from "../../assets/properties/ApprovalIcon.svg";
import image5 from "../../assets/properties/developerIcon.svg";
import image6 from "../../assets/properties/buildingsIcon.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import InquiryForm from "../../components/contact/Inquiry";
import { capitalizeFirstLetter } from "../../components/common/FirstLetterCapitalize";
import "../users/Services/BuildingDetail.css";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

const BuildingDetails = () => {
  const params = useParams();
  console.log(params?.title);
  const [activeTab, setActiveTab] = useState("Property Details");
  const [propertyDetail, setPropertyDetail] = useState({});
  const [selectedImage, setSeelectedImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(5);

  const GetBuildingData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://arapti.onrender.com/real-estate/buildings/get-building-by-id/${params?.id}`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("rdata", response?.data);
        setSeelectedImage(response?.data?.BuildingsImages[0]);

        setPropertyDetail(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    getBuildingDatas();
    GetBuildingData();
  }, []);

  const data = [
    {
      image: image1,
      name: "Area",
      detail: propertyDetail?.physicalCharacteristics?.totalArea + " " + "acre",
    },
    {
      image: image2,
      name: "Unit",
      detail:
        propertyDetail?.units?.map((ele) => ele?.unitType + "," + " ") || "",
    },
    {
      image: image3,
      name: "No. of Tower",
      detail: propertyDetail?.physicalCharacteristics?.numberOfTowers || 0,
    },
    {
      image: image4,
      name: "Approval",
      detail: propertyDetail?.generalInfo?.approval || "",
    },
    {
      image: image5,
      name: "Developer",
      detail:
        capitalizeFirstLetter(propertyDetail?.generalInfo?.developerName) || "",
    },
    {
      image: image6,
      name: "Building Type",
      detail:
        capitalizeFirstLetter(propertyDetail?.generalInfo?.BuildingsType) || "",
    },
  ];

  useEffect(() => {
    const updateVisibleCards = () => {
      if (window.innerWidth >= 1280) {
        setVisibleCards(5);
      } else if (window.innerWidth >= 1024) {
        setVisibleCards(4);
      } else if (window.innerWidth >= 768) {
        setVisibleCards(3);
      } else {
        setVisibleCards(1);
      }
    };

    window.addEventListener("resize", updateVisibleCards);
    updateVisibleCards();

    return () => window.removeEventListener("resize", updateVisibleCards);
  }, []);

  const handleNext = () => {
    if (currentIndex < propertyDetail?.BuildingsImages?.length - visibleCards) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getBuildingDatas = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://arapti.onrender.com/real-estate/buildings/get-all-buildings`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data);
        // const filterData = response?.data
        //   ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        //   .slice(0, 3);
        // setRelatedBuildings(filterData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <div
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="h-auto w-full bg-gradient-to-r from-[#F638B6] to-[#0C71BD]"
        >
          <div className="text-end py-2 px-8 text-white bg-[#12121265]">
            <h1 className="text-lg leading-[20px] font-bold tracking-wider">
              {capitalizeFirstLetter(
                propertyDetail?.generalInfo?.BuildingsName
              )}
            </h1>

            <div className="text-sm mt-2 text-gray-200 tracking-wide font-semibold">
              - Call For Price -
            </div>
          </div>

          <div className="rounded-lg border-2 lg:h-[500px] md:h-[400px] h-[300px] m-auto w-[90%] my-4 overflow-hidden">
            <img
              className="h-full w-full bg-black"
              src={selectedImage}
              alt="img"
            />
          </div>

          <div className="px-8 text-white bg-transparent flex items-center justify-between gap-4">
            <div>
              <button
                onClick={handlePrev}
                disabled={currentIndex === 0}
                className={`${
                  currentIndex === 0 ? "cursor-not-allowed opacity-50" : ""
                }`}
              >
                <IoIosArrowDropleftCircle size={30} />
              </button>
            </div>
            <div className="flex gap-8 overflow-x-scroll p-2 mb-2">
              {propertyDetail?.BuildingsImages?.slice(
                currentIndex,
                currentIndex + visibleCards
              ).map((ele, index) => (
                <div
                  key={index}
                  className="w-[100px] h-[100px] border-2 border-white rounded-lg overflow-hidden"
                >
                  <img
                    onClick={() => setSeelectedImage(ele)}
                    src={ele}
                    alt={`Building ${index}`}
                    className="w-full h-full object-cover cursor-pointer"
                  />
                </div>
              ))}
            </div>
            <div>
              <button
                onClick={handleNext}
                disabled={
                  currentIndex >= propertyDetail?.BuildingsImages?.length - 5
                }
                className={`${
                  currentIndex >= propertyDetail?.BuildingsImages?.length - 5
                    ? "cursor-not-allowed opacity-50"
                    : ""
                }`}
              >
                <IoIosArrowDroprightCircle size={30} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="px-10 py-10 tracking-wide">
        <div className="grid xl:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-4">
          {data?.map((ele) => (
            <div className="gap-4 border py-4 px-2 rounded-xl bg-gradient-to-tr from-[#6aabdd] to-[#f874cc]">
              <div className="w-full flex justify-center mb-2">
                <img src={ele?.image} alt="icons" className="w-10" />
              </div>
              <div className="flex flex-col gap-1 text-center">
                <h1 className="font-bold text-md tracking-wide">{ele?.name}</h1>
                <p className="text-sm font-semibold capitalize text-white">
                  {ele?.detail}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="lg:flex lg:flex-row gap-4 mt-8">
          <div className="h-full lg:w-[70%]">
            <div className="flex">
              <button
                className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${
                  activeTab === "Property Details" &&
                  "border-blue-500 text-blue-500"
                } focus:outline-none`}
                onClick={() => handleTabChange("Property Details")}
              >
                Property Details
              </button>
              <button
                className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${
                  activeTab === "Property Plan" &&
                  "border-blue-500 text-blue-500"
                } focus:outline-none`}
                onClick={() => handleTabChange("Property Plan")}
              >
                Property Plan
              </button>
              <button
                className={`py-2 w-full transition duration-500 ease-linear px-4 border-b-4  ${
                  activeTab === "Locations" && "border-blue-500 text-blue-500"
                } focus:outline-none`}
                onClick={() => handleTabChange("Locations")}
              >
                Locations
              </button>
            </div>

            <div className="mt-4">
              {activeTab === "Property Details" && (
                <div className="text-secondaryTextColor tracking-wider">
                  {propertyDetail?.propertyDescription?.map((ele) => (
                    <p class="text-secondaryTextColor mb-2">{ele}</p>
                  ))}

                  <p className="text-primaryBtnColor font-semibold text-center bg-[#c4daf9] p-2 rounded-lg mt-4">
                    Additional Information
                  </p>
                  <div className="mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    <div className="flex items-center gap-4">
                      <p className="text-black">Year Of Prossesion :</p>
                      <p>
                        {
                          propertyDetail?.physicalCharacteristics
                            ?.yearOfProssesion
                        }
                        Year
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">Parking Facilities :</p>
                      <p>
                        {
                          propertyDetail?.physicalCharacteristics
                            ?.parkingFacilities
                        }
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">No Of Tower :</p>
                      <p>
                        {
                          propertyDetail?.physicalCharacteristics
                            ?.numberOfTowers
                        }
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">Total Floors :</p>
                      <p>
                        {propertyDetail?.physicalCharacteristics?.totalFloors}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <p className="text-black">Maintenance Fees :</p>
                      <p>{propertyDetail?.financialInfo?.maintenanceFees}</p>
                    </div>
                  </div>

                  <p className="text-primaryBtnColor font-semibold text-center bg-[#c4daf9] p-2 rounded-lg mt-4">
                    Nearest Locations
                  </p>
                  <div className="mt-4 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                    {propertyDetail?.generalInfo?.locationBenifits?.map(
                      (ele) => (
                        <div className="flex items-center gap-4">
                          <p className="text-black">{ele?.name} :</p>
                          <p>{ele?.value}</p>
                        </div>
                      )
                    )}
                  </div>

                  <p className="text-primaryBtnColor font-semibold text-center bg-[#c4daf9] p-2 rounded-lg mt-4">
                    Aminities
                  </p>
                  <div className="mt-4 grid lg:grid-cols-3 grid-cols-2 gap-4 text-black">
                    {propertyDetail?.extraInfo?.amenities?.map((ele) => (
                      <div className="flex items-center gap-4">
                        <p className="text-black">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                          </svg>
                        </p>
                        <p>{ele}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {activeTab === "Property Plan" && (
                <div className="p-4">
                  <img
                    src={propertyDetail?.BuildingsPlansImage}
                    alt="map"
                    className="md:w-[80%] w-full h-auto m-auto"
                  />
                </div>
              )}
              {activeTab === "Locations" && (
                <div className="space-y-4 w-full mt-6">
                  <div className="bg-gray-100 rounded-lg p-8 h-full flex items-center">
                    <div className="flex items-center">
                      <div className="bg-violet-200 text-violet-500 hover:bg-violet-500 hover:text-white transition duration-200 ease-linear h-20 w-20 rounded-full flex items-center justify-center">
                        <CiLocationOn size={40} />
                      </div>
                      <div className="ml-6">
                        <h1 className="text-2xl font-bold text-secondaryTextColor">
                          Address
                        </h1>
                        <p className="text-xl mt-2 leading-[40px]">
                          {propertyDetail?.locationDetails?.address}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-100 rounded-lg p-8 h-full flex items-center">
                    <div className="flex items-center">
                      <div className="bg-violet-200 text-violet-500 hover:bg-violet-500 hover:text-white transition duration-200 ease-linear h-20 w-20 rounded-full flex items-center justify-center">
                        <CiLocationOn size={40} />
                      </div>
                      <div className="ml-6">
                        <h1 className="text-2xl font-bold text-secondaryTextColor">
                          City
                        </h1>
                        <p className="text-xl mt-2 leading-[40px]">
                          {propertyDetail?.locationDetails?.city}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-100 rounded-lg p-8 h-full flex items-center">
                    <div className="flex items-center">
                      <div className="bg-violet-200 text-violet-500 hover:bg-violet-500 hover:text-white transition duration-200 ease-linear h-20 w-20 rounded-full flex items-center justify-center">
                        <CiLocationOn size={40} />
                      </div>
                      <div className="ml-6">
                        <h1 className="text-2xl font-bold text-secondaryTextColor">
                          State
                        </h1>
                        <p className="text-xl mt-2 leading-[40px]">
                          {propertyDetail?.locationDetails?.state}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-100 rounded-lg p-8 h-full flex items-center">
                    <div className="flex items-center">
                      <div className="bg-violet-200 text-violet-500 hover:bg-violet-500 hover:text-white transition duration-200 ease-linear h-20 w-20 rounded-full flex items-center justify-center">
                        <CiLocationOn size={40} />
                      </div>
                      <div className="ml-6">
                        <h1 className="text-2xl font-bold text-secondaryTextColor">
                          Country
                        </h1>
                        <p className="text-xl mt-2 leading-[40px]">
                          {propertyDetail?.locationDetails?.country}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-100 rounded-lg p-8 h-full flex items-center">
                    <div className="flex items-center">
                      <div className="bg-violet-200 text-violet-500 hover:bg-violet-500 hover:text-white transition duration-200 ease-linear h-20 w-20 rounded-full flex items-center justify-center">
                        <CiLocationOn size={40} />
                      </div>
                      <div className="ml-6">
                        <h1 className="text-2xl font-bold text-secondaryTextColor">
                          Zip Code
                        </h1>
                        <p className="text-xl mt-2 leading-[40px]">
                          {propertyDetail?.locationDetails?.zipCode}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="h-full lg:w-[30%] lg:mt-0 mt-8">
            <InquiryForm />

            <div className="mt-8 bg-black flex items-center gap-4 p-6 rounded-md">
              <img src={care_icon} alt="name_card_below_form" className="" />
              <div className="text-white flex flex-col gap-2 tracking-wider">
                <h1 className="font-bold text-2xl">Arapti</h1>
                <p className="text-2xl font-medium">+91 9827844414</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingDetails;
