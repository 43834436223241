import React from 'react';
import member1 from "../../assets/about/director1.jpeg";
import member2 from "../../assets/about/director2.jpeg";

const Director = () => {
  return (
    <React.Fragment>
      <h3 className="text-center font-semibold text-2xl md:text-3xl mt-5">

      </h3>
      <div className="flex flex-col items-center justify-center">
        <h1 className="font-semibold text-3xl md:text-4xl tracking-wide">
        Our Leads
        </h1>
        <p className="text-gray-400 text-sm mt-3 text-center max-w-3xl tracking-wide">
        "Our Leads guide the team with vision and expertise, driving innovation and success. They ensure quality, foster collaboration, and lead by example, ensuring every project meets the highest standards."
        </p>
      </div>

      <div className='flex flex-col md:flex-row w-full space-y-6 md:space-y-0 md:space-x-10 mx-auto px-6 mt-5 rounded-md'>
        <div className='w-full md:w-[48%] shadow-lg p-2 rounded-md'>
          <img src={member2} alt='Director 1' className='w-full h-auto' />
        </div>
        <div className='w-full md:w-[48%] shadow-lg p-2 rounded-md'>
          <img src={member1} alt='Director 2' className='w-full h-auto' />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Director;

