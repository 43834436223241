import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const LandAddForm = () => {
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    generalInfo: {
      propertyName: "",
      propertyType: "",
      currentStatus: "",
    },
    locationDetails: {
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    },
    physicalCharacteristics: {
      totalArea: "",
      terrainType: "",
      soilType: "",
      roadAccess: "",
    },
    financialInfo: {
      sellingPrice: "",
    },
    utilities: {
      waterAccess: "",
      sewageSystem: "",
      electricityAccess: "",
    },
    propertyImages: [],
  });

  const handleClear = () => {
    setFormData({
      generalInfo: {
        propertyName: "",
        propertyType: "",
        currentStatus: "",
      },
      locationDetails: {
        address: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
      },
      physicalCharacteristics: {
        totalArea: "",
        terrainType: "",
        soilType: "",
        roadAccess: "",
      },
      financialInfo: {
        sellingPrice: "",
      },
      utilities: {
        waterAccess: "",
        sewageSystem: "",
        electricityAccess: "",
      },
      propertyImages: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any field is empty
    const isEmpty = Object.values(formData).some((field) => {
      if (typeof field === "object" && !Array.isArray(field)) {
        return Object.values(field).some((subField) => subField === "");
      } else if (Array.isArray(field) && field.length === 0) {
        return true;
      }
      return field === "";
    });

    if (isEmpty) {
      toast.warning("Please fill all the fields.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://arapti.onrender.com/real-estate/lands/add-land`,
        formData
      );
      // console.log(response.data);
      if (response.data) {
        setIsLoading(false);
        handleClear();
        toast.success("Land added successfully");
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split(".");
    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value,
      },
    }));
  };

  const handleImageChange = async (e) => {
    const imageFiles = e.target.files;
    setImageUploadLoading(true);

    try {
      const uploadPromises = Array.from(imageFiles).map(async (imageFile) => {
        const formDataCloudinary = new FormData();
        formDataCloudinary.append("file", imageFile);
        formDataCloudinary.append("upload_preset", "Assavio Buildings");

        const cloudinaryResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/dhysboq8w/image/upload`,
          formDataCloudinary
        );
        return cloudinaryResponse?.data?.secure_url;
      });

      const cloudinaryUrls = await Promise.all(uploadPromises);

      setImageUploadLoading(false);

      setFormData((prevState) => ({
        ...prevState,
        propertyImages: [...prevState.propertyImages, ...cloudinaryUrls],
      }));
    } catch (error) {
      console.error("Error submitting form:", error);
      setImageUploadLoading(false);
    }

    // const imageFile = e.target.files[0];
    // setImageUploadLoading(true);

    //   const formDataCloudinary = new FormData();
    //   formDataCloudinary.append("file", imageFile);
    //   formDataCloudinary.append("upload_preset", "Assavio Buildings");

    //   const cloudinaryResponse = await axios.post(
    //     "https://api.cloudinary.com/v1_1/dhysboq8w/image/upload",
    //     formDataCloudinary
    //   );
    //   const cloudinaryUrl = cloudinaryResponse?.data?.secure_url;
    //   if (cloudinaryResponse?.data?.secure_url) {
    //     setImageUploadLoading(false);
    //   }
    //   // console.log(cloudinaryUrl);
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     propertyImages: [...prevState.propertyImages, cloudinaryUrl],
    //   }));
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    //   setImageUploadLoading(false);
    // }
  };
  const handleRemoveImage = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      propertyImages: prevState.propertyImages.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="container mx-auto px-6">
      <ToastContainer />
      {imageUploadLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      <h2 className="text-2xl font-normal ml-6 mt-4 mb-4">Add Land</h2>
      <form
        onSubmit={handleSubmit}
        className="w-full border p-6 h-[85vh] overflow-y-scroll rounded-xl"
      >
        {/* General Info */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">General Info</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Property Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Property Name"
            name="generalInfo.propertyName"
            value={formData.generalInfo.propertyName}
            onChange={handleChange}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Property Type
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="generalInfo.propertyType"
            value={formData.generalInfo.propertyType}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Property Type
            </option>
            <option value="agricultural">Agricultural</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="industrial">Industrial</option>
          </select>
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Current Status
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="generalInfo.currentStatus"
            value={formData.generalInfo.currentStatus}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Current Status
            </option>
            <option value="available">Available</option>
            <option value="under contract">Under Contract</option>
            <option value="sold">Sold</option>
          </select>
        </div>

        {/* Location Details */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Location Details</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Address
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Address"
            name="locationDetails.address"
            value={formData.locationDetails.address}
            onChange={handleChange}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            City
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="City"
            name="locationDetails.city"
            value={formData.locationDetails.city}
            onChange={handleChange}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            State
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="State"
            name="locationDetails.state"
            value={formData.locationDetails.state}
            onChange={handleChange}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Zip Code
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Zip Code"
            name="locationDetails.zipCode"
            value={formData.locationDetails.zipCode}
            onChange={handleChange}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Country
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Country"
            name="locationDetails.country"
            value={formData.locationDetails.country}
            onChange={handleChange}
            required
          />
        </div>

        {/* Physical Characteristics */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">
            Physical Characteristics
          </h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Total Area
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Total Area (sq ft)"
            name="physicalCharacteristics.totalArea"
            value={formData.physicalCharacteristics.totalArea}
            onChange={handleChange}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Terrain Type
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="physicalCharacteristics.terrainType"
            value={formData.physicalCharacteristics.terrainType}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Terrain Type
            </option>
            <option value="flat">Flat</option>
            <option value="hilly">Hilly</option>
            <option value="wooded">Wooded</option>
            <option value="cleared">Cleared</option>
          </select>
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Soil Type
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Soil Type"
            name="physicalCharacteristics.soilType"
            value={formData.physicalCharacteristics.soilType}
            onChange={handleChange}
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Road Access
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="physicalCharacteristics.roadAccess"
            value={formData.physicalCharacteristics.roadAccess}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Road Access
            </option>
            <option value="paved">Paved</option>
            <option value="dirt">Dirt</option>
            <option value="no access">No Access</option>
          </select>
        </div>

        {/* Financial Info */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Financial Info</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Selling Price
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Selling Price"
            name="financialInfo.sellingPrice"
            value={formData.financialInfo.sellingPrice}
            onChange={handleChange}
            required
          />
        </div>

        {/* Utilities */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Utilities</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Water Access
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="utilities.waterAccess"
            value={formData.utilities.waterAccess}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Water Access
            </option>
            <option value="municipal">Municipal</option>
            <option value="well">Well</option>
            <option value="none">None</option>
          </select>
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Sewage System
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="utilities.sewageSystem"
            value={formData.utilities.sewageSystem}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Sewage System
            </option>
            <option value="municipal">Municipal</option>
            <option value="septic">Septic</option>
            <option value="none">None</option>
          </select>
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Electricity Access
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="utilities.electricityAccess"
            value={formData.utilities.electricityAccess}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Electricity Access
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        {/* Property Images */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Property Images</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Upload Images
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="file"
            name="propertyImages"
            multiple
            onChange={handleImageChange}
            accept="image/*"
          />
        </div>
        <div
          className={`${
            formData?.BuildingsImages?.length < 1 && "hidden"
          } flex flex-wrap gap-4 mt-4 border p-2 rounded my-3`}
        >
          {formData?.propertyImages?.map((ele, index) => (
            <div key={index}>
              <img src={ele} alt="Building Images" className="w-16 h-16" />
              <button
                onClick={() => handleRemoveImage(index)}
                className="text-center w-full text-red-500 my-3"
              >
                Remove
              </button>
            </div>
          ))}
        </div>

        {/* Submit Button */}
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Submit Property
          </button>
        </div>
      </form>
    </div>
  );
};

export default LandAddForm;
