import React from "react";
import user from "../../assets/about/AboutUser.png";

const Benifit = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full px-8 mt-6">
      <div className="text-center">
        <h1 className="font-semibold text-3xl">
          What Benefits Do You Get From Us
        </h1>
        <p className="mt-2 text-gray-400 text-sm">
          Work with Renting brokers who help you to get started with smart tools to.
        </p>
      </div>
      <div className="mt-5 flex flex-col sm:flex-row gap-6">
        <div className="bg-white shadow-lg rounded-lg p-8 text-center flex-1">
          <img src={user} alt="Local Expertise and Market Knowledge" className="mx-auto mb-4 w-24 h-24 object-cover rounded-full" />
          <h3 className="font-semibold text-xl">
            Local Expertise and Market Knowledge
          </h3>
          <p className="text-gray-400 text-md tracking-wide mt-2">
            Our team has deep roots in the community and a thorough understanding of the local real estate market.
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-8 text-center flex-1">
          <img src={user} alt="Personalized and Client-Centric Service" className="mx-auto mb-4 w-24 h-24 object-cover rounded-full" />
          <h3 className="font-semibold text-xl">
            Personalized and Client-Centric Service
          </h3>
          <p className="text-gray-400 text-md tracking-wide mt-2">
            We believe in building lasting relationships by putting our clients' needs first. Our personalized approach ensures.
          </p>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-8 text-center flex-1">
          <img src={user} alt="Comprehensive Support and Resources" className="mx-auto mb-4 w-24 h-24 object-cover rounded-full" />
          <h3 className="font-semibold text-xl">
            Comprehensive Support and Resources
          </h3>
          <p className="text-gray-400 text-md tracking-wide mt-2">
            From initial consultation to closing and beyond, we provide comprehensive support throughout the entire real estate process.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Benifit;



