import React from "react";
import logo from "../../assets/logos/whiteLogo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";

const UserFooter = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <footer>
        <div className="flex flex-col lg:flex-row w-full mx-auto lg:space-x-16 bg-[#1E2027] text-white p-6 md:p-10 lg:p-20">
         
          <div className="w-full lg:w-[40%] mb-8 lg:mb-0">
            <img src={logo} alt="Logo" className="h-auto w-40 sm:w-48 md:w-60 mb-3" />

            <p className="font-medium text-justify text-sm md:text-base tracking-wider md:text-start text-secondaryTextColor">
              Arapti offers a seamless avenue for establishing a cutting-edge
              investment platform without necessitating coding expertise within
              a short time.
            </p>
            <div className="flex space-x-4 mt-4 text-[#8DB8FE] text-xl sm:text-2xl">
              <Link
                to="https://www.facebook.com/profile.php?id=61565248872857&mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF size={18} className="cursor-pointer" />
              </Link>

              <Link
                to="https://www.instagram.com/arepl2024?igsh=MTJkZGtndjdiZ3J1bQ=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareInstagram size={18} className="cursor-pointer" />
              </Link>
            </div>
          </div>

          
          <div className="w-full lg:w-[60%] flex flex-col sm:flex-row tracking-wide">
            <div className="mb-8 lg:mb-0 w-full sm:w-[50%]">
              <h3 className="text-white font-semibold mb-4">Quick Links</h3>
              <div>
                <div className="mt-4 text-secondaryTextColor">
                  <button onClick={() => { navigate('/'); }}>Home</button>
                </div>
                <div className="mt-4 text-secondaryTextColor">
                  <button onClick={() => { navigate('/about'); }}>About Us</button>
                </div>
                <div className="mt-4 text-secondaryTextColor">
                  <button onClick={() => { navigate('/properties'); }}>Properties</button>
                </div>
                <div className="mt-4 text-secondaryTextColor">
                  <button onClick={() => { navigate('/contact'); }}>Contact</button>
                </div>
              </div>
            </div>

            <div className="w-full sm:w-[50%] tracking-wide">
              <h3 className="text-white font-semibold mb-4">Contact Us</h3>
              <p className="text-sm text-secondaryTextColor">
                Plot No-508, Near DM Public school, Durgapurpatna, Basuaghai, Puribypass Road. Pin-751002
              </p>
              <p className="text-sm mt-4">
                <a href="mailto:Example@example.com" className="text-secondaryTextColor">
                  araptirealestatepvtltd89@gmail.com
                </a>
              </p>
              <p className="text-sm mt-4 text-secondaryTextColor">+91 9827844414</p>
            </div>
          </div>
        </div>
      </footer>

      <div className="flex flex-col sm:flex-row justify-between items-center w-full py-2 px-6 text-sm bg-[#0a0b0b] text-white tracking-wider">
        <div className="mb-2 sm:mb-0 text-center sm:text-left">
          Privacy Policy | Terms & Conditions
          <span> &nbsp;© copyright, </span>
          <span className="text-primaryColor font-semibold">Arapti</span>
        </div>

        <div className="text-center sm:text-right">
          <span
            className="text-[#fe8740] cursor-pointer"
            onClick={() => window.open("https://webbocket.com/")}
          >
            Web_Bocket PVT LTD.
          </span>
          All Rights Reserved.
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserFooter;
