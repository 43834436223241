import React from "react";
import UserHeader from "../../../components/common/UserHeader";
import { FaStar } from "react-icons/fa";

const Testimonials = () => {
  const testimonialsData = [
    {
      name: "Tapan Mishra",
      designation: "Real Estate Investor",
      gender: "male",
      img_url: "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png",
      msg: "Buying a property can be daunting, but the team at Arapti made the process seamless and stress-free. Their market insights and attention to detail ensured I made a smart investment. The transparency and guidance they provided were invaluable. Thank you for helping me find my dream property!",
      rating: 5,
    },
    {
      name: "Seetal Kumari",
      designation: "Property Developer",
      gender: "female",
      img_url:
        "https://static.vecteezy.com/system/resources/thumbnails/004/899/680/small_2x/beautiful-blonde-woman-with-makeup-avatar-for-a-beauty-salon-illustration-in-the-cartoon-style-vector.jpg",
      msg: "I’ve worked with many real estate agents before, but none have shown the market expertise and dedication as our agent, Anita Rodriguez did. Thanks to Arapti insights, we've successfully expanded our investment portfolio. She’s our go-to for all real estate investments now!",
      rating: 4,
    },
    {
      name: "Rahul Tripathy",
      designation: "Home Buyer",
      gender: "male",
      img_url: "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png",
      msg: "The dedication and hard work of the team at Arapti were remarkable. From initial consultation to finalizing the deal, they were thorough and proactive. Their understanding of the Bangalore real estate market is unparalleled. I felt confident and well-informed throughout the purchasing process.",
      rating: 5,
    },
    {
      name: "Rajesh Kumar",
      designation: "Home Buyer",
      gender: "male",
      img_url: "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png",
      msg: "When we needed a bigger home to accommodate our growing family, we turned to Greg Daniels. He listened carefully to what we wanted and found us a home that checks all the boxes and then some. We are so happy in our new space and can't thank Greg enough!",
      rating: 4,
    },
    {
      name: "Bikash Mohapatra",
      designation: "Luxury Property Owner",
      gender: "male",
      img_url: "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png",
      msg: "When it comes to luxury properties, our agent, Charles Webb, is truly a cut above. His discretion, attention to detail, and deep understanding of high-end markets led us to a stunning property that exceeded our expectations.",
      rating: 5,
    },
    {
      name: "Rupali Panda",
      designation: "Downsizer",
      gender: "female",
      img_url:
        "https://static.vecteezy.com/system/resources/thumbnails/004/899/680/small_2x/beautiful-blonde-woman-with-makeup-avatar-for-a-beauty-salon-illustration-in-the-cartoon-style-vector.jpg",
      msg: "After deciding to downsize, we were so grateful for our agent's expertise and sensitivity to our needs. She helped us find a smaller home that still feels just right, managing every detail with care and precision.",
      rating: 4,
    },
  ];

  return (
    <React.Fragment>
      <UserHeader pageName="Our Testimonials" mainText="Home" />
      <div className="bg-gray-100 py-10 px-6 md:px-12">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">
            What Our Clients Say
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonialsData.map((testimonial, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center"
              >
                <img
                  src={testimonial.img_url}
                  alt={testimonial.name}
                  className="w-24 h-24 rounded-full mb-4"
                />
                <h3 className="text-xl font-semibold text-center tracking-wider">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-500 text-center my-2 tracking-wide">
                  {testimonial.designation}
                </p>
                <div className="flex mb-4">
                  {Array.from({ length: testimonial.rating }).map((_, i) => (
                    <FaStar key={i} size={24} className="text-yellow-400" />
                  ))}
                </div>
                <p className="text-center tracking-wider text-gray-500 mb-4">
                  {testimonial.msg}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonials;
