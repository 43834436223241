import React, { useEffect, useState } from 'react';
import Card from './Card';
import axios from 'axios';
import LandsCard from './LandsCard';

const TrendingProperties = () => {
    const [properties, setProperties] = useState([]);
    const [allLands, setAllLands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [landIsLoading, setLandIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [propertyType, setPropertyType] = useState('buildings');

    useEffect(() => {
        getAllProperties();
        getAllLands();
    }, [propertyType]);

    const getAllProperties = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://arapti.onrender.com/real-estate/${propertyType}/get-all-${propertyType}`,
            headers: {},
            data: ''
        };

        setIsLoading(true);
        axios.request(config)
            .then((response) => {
                setProperties(response?.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching properties:', error);
                setIsLoading(false);
            });
    };

    const getAllLands = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://arapti.onrender.com/real-estate/lands/get-all-lands',
            headers: {},
            data: ''
        };

        setLandIsLoading(true);
        axios.request(config)
            .then((response) => {
                console.log('Lands data:', response?.data); // Debugging line
                setAllLands(response?.data);
                setLandIsLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching lands:', error);
                setLandIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <div className="flex flex-col sm:flex-row justify-between items-center my-6 px-4 sm:px-12">
                <div className="w-full sm:flex-1 sm:mr-4 max-w-full sm:max-w-lg mb-4 sm:mb-0">
                    <input
                        type="text"
                        placeholder={`Search ${propertyType === 'buildings' ? 'Buildings' : 'Lands'}...`}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                <div className="flex items-center space-x-4">
                    <label className="flex items-center space-x-2">
                        <span className="text-gray-600 tracking-wide font-semibold text-md">Land</span>
                        <div className="relative">
                            <input
                                type="checkbox"
                                id="property-type-toggle"
                                className="sr-only"
                                checked={propertyType === 'buildings'}
                                onChange={() =>
                                    setPropertyType((prevType) =>
                                        prevType === 'land' ? 'buildings' : 'land'
                                    )
                                }
                            />
                            <div className="block bg-[#dde6ee] w-16 h-8 rounded-full"></div>
                            <div
                                className="dot absolute left-1 top-1 bg-primaryBtnColor w-6 h-6 rounded-full transition-transform duration-300"
                                style={{
                                    transform:
                                        propertyType === 'buildings'
                                            ? 'translateX(100%)'
                                            : 'translateX(0)',
                                }}
                            ></div>
                        </div>
                        <span className="text-gray-600 tracking-wide font-semibold text-md">Buildings</span>
                    </label>
                </div>
            </div>

            {
                propertyType === 'buildings'
                &&
                <React.Fragment>
                    <div className='text-xl text-center font-bold tracking-wider text-primaryColor'>
                        Buildings List
                    </div>

                    <div className="mb-6 px-12 grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 w-full mt-6">
                        {
                            !isLoading
                                ?
                                properties
                                    .filter(property => property?.generalInfo?.BuildingsName?.toLowerCase().includes(searchTerm.toLowerCase())) // Filter based on searchTerm
                                    .map((property, index) => (
                                        <Card
                                            key={index}
                                            property={property}
                                        />
                                    ))
                                :
                                <BuildingsSkeleton />
                        }
                    </div>
                </React.Fragment>
            }

            {propertyType === 'land' && (
                <React.Fragment>
                    <div className="text-xl text-center font-bold tracking-wider text-primaryColor">
                        Lands List
                    </div>

                    <div className="px-12 my-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
                        {!landIsLoading
                            ? allLands
                                .filter(land =>
                                    land?.generalInfo?.propertyName?.toLowerCase().includes(searchTerm.toLowerCase())
                                )
                                .map((land, index) => (
                                    <LandsCard
                                        key={index}
                                        land={land}
                                    />
                                ))
                            : <LandsSkeleton />}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default TrendingProperties;

export const BuildingsSkeleton = () => {
    return (
        [1, 2, 3, 4].map((i) => (
            <div key={i} className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col md:flex-row">
                <div className="relative w-full md:w-1/3">
                    <div className="w-full h-48 md:h-full bg-gray-200 animate-pulse"></div>

                    <div className="absolute top-4 left-4 bg-white px-2 py-1 rounded-full">
                        <div className="w-16 h-6 bg-gray-200 animate-pulse"></div>
                    </div>

                    <div className="absolute bottom-4 left-4">
                        <div className="w-10 h-10 bg-gray-200 rounded-full animate-pulse"></div>
                    </div>
                </div>

                <div className="p-4 md:p-6 flex-1 flex flex-col">
                    <div className="flex justify-between items-start mb-4">
                        <div className="w-3/4 h-6 bg-gray-200 animate-pulse"></div>

                        <div className="w-9 h-9 bg-gray-200 rounded-full animate-pulse"></div>
                    </div>

                    <div className="w-2/5 h-5 bg-gray-200 animate-pulse mb-4"></div>

                    <div className="flex flex-wrap gap-4 mb-4 text-gray-600 text-sm tracking-wide">
                        <div className="w-24 h-5 bg-gray-200 animate-pulse"></div>
                        <div className="w-24 h-5 bg-gray-200 animate-pulse"></div>
                        <div className="w-24 h-5 bg-gray-200 animate-pulse"></div>
                    </div>

                    <div className="w-4/5 h-5 bg-gray-200 animate-pulse"></div>
                </div>
            </div>
        ))
    );
}

export const LandsSkeleton = () => {
    return (
        [1, 2, 3, 4, 5, 6].map((i) => (
            <div key={i} className="bg-white rounded-xl shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300">
                <div className="relative">
                    <div className="h-48 w-full bg-gray-300 animate-pulse" />

                    <div className="absolute top-4 left-4 bg-gray-300 text-white text-sm font-medium px-3 py-1 rounded-full animate-pulse w-20 h-6"></div>
                </div>

                <div className="p-4">
                    <div className="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>

                    <div className="flex items-center text-gray-500 mt-2">
                        <div className="h-6 w-6 bg-gray-300 rounded-full animate-pulse mr-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-4/5 animate-pulse"></div>
                    </div>

                    <div className="flex items-center text-gray-500 mt-2">
                        <div className="h-6 w-6 bg-gray-300 rounded-full animate-pulse mr-2"></div>
                        <div className="h-4 bg-gray-300 rounded w-2/5 animate-pulse"></div>
                    </div>
                </div>
            </div>
        ))
    );
}
