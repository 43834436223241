import React from "react";
import UserHeader from "../../components/common/UserHeader";
import frame from "../../assets/frame.svg";
import Benifit from "../../components/About/Benifit";
import Ourmission from "../../components/About/Ourmission";
import Amenities from "../../components/About/Amenities";
import TeamMember from "../../components/About/TeamMember";
import Director from "../../components/About/Director";
import Familyfame from "../../components/About/Familyfame"

const Home = () => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-[100%] overflow-hidden">
      <UserHeader headerImg={frame} pageName="About Us" mainText="Home" />
      <Benifit />
      <Ourmission />
      <Director />
      <TeamMember />
      <Familyfame/>
      <Amenities />
    </div>
  );
};

export default Home;