import React, { useEffect } from "react";
import UserHeader from "../../../components/common/UserHeader";
import group1 from "../../../assets/Group12.png";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DiscoverServices } from "./OurTopServices";

const Services = () => {
  const navigate = useNavigate();
  
  return (
    <React.Fragment>
      <UserHeader pageName="Service Detail" mainText="Home" />
      <div className="p-10">
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div>
            <h1 className="lg:text-[40px] text-lg text-[#212121] font-semibold tracking-wide lg:leading-10 lg:w-[80%]">
              DISCOVER VERIFIED PROPERTIES
            </h1>
          </div>
          <div>
            <img src={group1} alt="group1" />
          </div>
        </div>

        <div className="flex text-[16px] text-[#8B8989] tracking-wider mt-4 text-justify">
          At Arapti, we understand the importance of trust and transparency
          when it comes to finding your perfect property. That's why we're proud
          to offer a curated selection of verified properties, ensuring that
          every listing meets our high standards of quality, authenticity, and
          reliability.
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Verified Properties :
            </span>{" "}
            Our team of real estate experts meticulously evaluates each property
            to ensure it meets our stringent criteria for verification. From
            comprehensive background checks to thorough inspections, we go above
            and beyond to verify the authenticity and integrity of every
            listing, giving you peace of mind and confidence in your real estate
            investment
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Tailored Recommendations :
            </span>{" "}
            Whether you're searching for a cozy family home, a luxury
            condominium, or a prime commercial space, we're here to help you
            find the perfect property to suit your needs and preferences. With
            our personalized approach and in-depth knowledge of the market,
            we'll provide you with tailored recommendations that align with your
            lifestyle, budget, and investment goals.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Transparent Transactions :
            </span>{" "}
            At Arapti, transparency is our priority. We believe in providing
            our clients with all the information they need to make informed
            decisions about their real estate investments. From detailed
            property profiles to clear pricing information, we ensure that every
            transaction is conducted with honesty, integrity, and transparency.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Expert Guidance :
            </span>{" "}
            Navigating the real estate market can be overwhelming, but you don't
            have to do it alone. Our team of experienced real estate
            professionals is here to guide you through every step of the
            process, from property search to closing. Whether you're a
            first-time homebuyer or an experienced investor, we'll provide you
            with expert guidance and support to help you make the best decisions
            for your future.
          </p>
        </div>

        <div className="mt-4">
          <h1 className="lg:text-[40px] text-2xl text-[#212121] font-semibold tracking-wide w-[80%]">
            Related Services
          </h1>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1  justify-between gap-8 mt-4">
          {DiscoverServices?.map((ele, index) => (
            <div
              key={index}
              onClick={() => {
                navigate(ele?.path);
                window.scrollTo(0, 0);
              }}
              className="relative border rounded-xl overflow-hidden cursor-pointer group"
            >
              <img
                className="m-auto h-[250px] w-full"
                src={ele?.image}
                alt={ele?.title + " " + "Image"}
              />
              <div className="absolute inset-0 flex flex-col justify-between p-4 lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:-translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center">{ele?.title}</p>
                </div>
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center line-clamp-2">{ele?.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Services;
