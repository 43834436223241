import React, { useEffect } from "react";
import UserHeader from "../../../components/common/UserHeader";
import group1 from "../../../assets/post-support.png";
import { FaArrowRight } from "react-icons/fa";
import { SuportServices } from "./OurTopServices";
import { useNavigate } from "react-router-dom";

const SupportService = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <UserHeader pageName="Service Detail" mainText="Home" />
      <div className="p-10">
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div>
            <h1 className="lg:text-[40px] text-lg text-[#212121] font-semibold tracking-wide lg:leading-10 lg:w-[80%]">
              PLOT-SALE SUPPORT SERVICES
            </h1>
          </div>
          <div>
            <img src={group1} alt="group1" />
          </div>
        </div>

        <div className="flex flex-col text-[16px] text-[#8B8989] tracking-wider mt-4 text-justify">
          <p>
            At Arapti, our commitment to our clients doesn't end with the sale.
            We understand that purchasing a property is just the beginning of
            your journey, and we are dedicated to providing you with ongoing
            support and assistance every step of the way.
          </p>
          <p>
            Our post-sale support services are designed to ensure that your
            transition into your new property is smooth and hassle-free. Whether
            you're moving into your dream home, setting up your new business
            space, or beginning to manage your investment property, we're here
            to help make the process as seamless as possible.
          </p>

          <p>Here's how our post-sale support can benefit you:</p>
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Move-in Assistance :
            </span>{" "}
            We can provide you with guidance and assistance to help make your
            move-in process easier. From coordinating with movers to arranging
            for utilities and services, we'll ensure that you have everything
            you need to settle into your new property comfortably.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Property Management Services :
            </span>{" "}
            If you've purchased an investment property, our team can help you
            manage it effectively. We offer a range of property management
            services, including tenant screening, rent collection, maintenance
            coordination, and financial reporting, to help you maximize your
            investment returns while minimizing your workload.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Renovation and Improvement Recommendations :
            </span>{" "}
            If you're looking to make updates or improvements to your property,
            we can connect you with trusted contractors, designers, and other
            professionals who can help bring your vision to life. Whether it's a
            small renovation project or a major overhaul, we'll help you find
            the right experts for the job.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Neighborhood Resources :
            </span>{" "}
            As local experts, we can provide you with valuable insights and
            recommendations about your new neighborhood. Whether you're looking
            for the best schools, parks, restaurants, or service providers,
            we'll help you get acquainted with your surroundings and settle into
            your new community.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Interior Decorating :
            </span>{" "}
            Whether you're moving into a new home or looking to refresh your
            current space, our interior decorating services can help you bring
            your vision to life. From selecting paint colors and fabrics to
            choosing furniture and accessories, our designers will work with you
            to create a cohesive and stylish interior that reflects your taste
            and lifestyle.
          </p>

          <p className="text-[#8B8989] text-justify">
            <span className="text-[#A24319] font-semibold">
              Ongoing Support and Assistance:
            </span>{" "}
            Our team is always here to answer your questions, address your
            concerns, and provide you with any additional support you may need.
            Whether you're facing challenges with your property or simply need
            advice, we're just a phone call or email away. At Arapti, we
            believe that our relationship with our clients extends far beyond
            the closing table. We're committed to being your trusted partner for
            all your real estate needs, both now and in the future. With our
            post-sale support services, you can feel confident knowing that you
            have a dedicated team by your side every step of the way.
          </p>
        </div>

        <div className="mt-4">
          <h1 className="lg:text-[40px] text-2xl text-[#212121] font-semibold tracking-wide w-[80%]">
            Related Services
          </h1>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1  justify-between gap-8 mt-4">
          {SuportServices?.map((ele, index) => (
            <div
              key={index}
              onClick={() => {
                navigate(ele?.path);
                window.scrollTo(0, 0);
              }}
              className="relative border rounded-xl overflow-hidden cursor-pointer group"
            >
              <img
                className="m-auto h-[250px] w-full"
                src={ele?.image}
                alt={ele?.title + " " + "Image"}
              />
              <div className="absolute inset-0 flex flex-col justify-between p-4 lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:-translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center">{ele?.title}</p>
                </div>
                <div className="text-white bg-black bg-opacity-40 p-2 transform lg:translate-y-full lg:group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                  <p className="text-center line-clamp-2">{ele?.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupportService;
