import React from "react";
import frame from "../../assets/headerImg.png";

const UserHeader = ({ mainText, pageName }) => {
  const [firstWord, secondWord] = pageName.split(" ");
  return (
    <header
      style={{ backgroundImage: `url(${frame})` }}
      className="bg-cover bg-center flex flex-col md:flex-row items-center w-full h-80 py-4 lg:px-20 md:px-10 px-5 md:p-10 "
    >
      <div className="w-full flex flex-col md:flex-row items-center justify-center md:mt-0 mt-12">
        <div>
          <h1 className="text-white tracking-wider text-3xl md:text-4xl font-bold text-center">
            {/* {pageName} */}
            <span className="text-white">{firstWord}&nbsp;</span>
            <span className="text-primaryBtnColor">
              {secondWord}
            </span>
          </h1>
        </div>
       
      </div>
    </header>
  );
};
export default UserHeader;