import React from 'react'
import img from "../../assets/about/family2.png"
const Familyfame = () => {
  return (
    <div className='px-10'>
      <div className="flex flex-col items-center justify-center">
        <h1 className="font-semibold text-3xl md:text-4xl tracking-wide">
          Our Family
        </h1>
        <p className="text-gray-400 text-sm mt-3 text-center max-w-3xl tracking-wide">
          The Arapti family stands as a symbol of unity, love, and strength. Rooted in tradition yet embracing the future, we are proud of the values that bind us together. From generation to generation, we’ve shared in life's challenges and joys.
        </p>
      </div>
      <img src={img} alt='img' className='mt-8' />
    </div>
  )
}

export default Familyfame