import React, { useState } from 'react';
import UserHeader from '../../components/common/UserHeader';
import { useNavigate } from 'react-router-dom';
import ApplicationModal from '../../components/careerModal/ApplicationModal';
import careerIcon from "../../assets/careericon.png";

const Career = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});

    const careerInfo = [
        {
            id: 1,
            role: "Digital Marketing Associate",
            type: "Full-Time",
            location: "Bhubaneswar",
            job_desc: "As a Digital Marketing Associate at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence.",
        },
        {
            id: 2,
            role: "Marketing Manager",
            type: "Full-Time",
            location: "Bhubaneswar",
            job_desc: "As a Marketing Manager at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence.",
        },
        {
            id: 3,
            role: "Business Development Executive",
            type: "Full-Time",
            location: "Bhubaneswar",
            job_desc: "As a BDE at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence.",
        },
        {
            id: 4,
            role: "Telecaller",
            type: "Full-Time",
            location: "Bhubaneswar",
            job_desc: "As a Telecaller at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence.",
        },
        {
            id: 5,
            role: "HR Generalist",
            type: "Full-Time",
            location: "Bhubaneswar",
            job_desc: "As a HR Generalist at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence.",
        },
        {
            id: 6,
            role: "Project Coordinator",
            type: "Full-Time",
            location: "Bhubaneswar",
            job_desc: "As a Project Coordinator at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence.",
        },
    ];

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <React.Fragment>
            <UserHeader
                pageName="Career"
                mainText="Home"
            />

            {showModal && (
                <ApplicationModal
                    data={data}
                    closeModal={toggleModal}
                />
            )}

            <div className="bg-gray-100 py-10">
                <div className="max-w-screen-xl mx-auto px-6">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-primaryTextColor tracking-wider">
                            Join Our Team
                        </h1>
                        <p className="text-base text-secondaryTextColor tracking-wide mt-4">
                            Start your career with a leading real estate company and help us shape the future of real estate.
                        </p>
                    </div>

                    <div className="mt-12">
                        <h2 className="text-2xl font-semibold text-primaryBtnColor tracking-wider text-center">
                            Open Positions
                        </h2>
                        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {careerInfo.map((data, i) => (
                                <div
                                    key={i}
                                    onClick={() => {
                                        toggleModal();
                                        setData(data);
                                    }}
                                    className="relative bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 cursor-pointer"
                                >
                                    <div className="absolute top-2 right-2 tracking-wide bg-blue-500 text-white px-2 py-1 text-xs rounded">
                                        {data.type}
                                    </div>
                                    <h3 className="text-xl font-bold text-primaryColor tracking-wider mb-2">
                                        {data.role}
                                    </h3>
                                    <p className="text-sm text-gray-700 mb-4 tracking-wide">
                                        {data.location}
                                    </p>
                                    <p className="text-sm text-secondaryTextColor line-clamp-3 tracking-wider">
                                        {data.job_desc}
                                    </p>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleModal();
                                            setData(data);
                                        }}
                                        className="mt-4 inline-block bg-primaryBtnColor text-white px-4 py-2 rounded hover:bg-primaryBtnHoverColor"
                                    >
                                        More Details
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mt-16">
                        <h2 className="text-3xl font-bold text-center text-gray-900">
                            Why Join Arapti?
                        </h2>
                        <p className="text-center text-gray-600 mt-4 mb-10 text-lg">
                            Discover the benefits of being part of our innovative team.
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                            <div className="text-center bg-gradient-to-r from-green-400 to-blue-500 text-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
                                <div className="flex items-center justify-center mb-4">
                                    <img
                                        src="https://img.icons8.com/ios-filled/50/ffffff/innovation.png"
                                        alt="Innovation Icon"
                                    />
                                </div>
                                <h4 className="font-bold text-xl mb-2 tracking-wider">Innovative Environment</h4>
                                <p className="text-base tracking-wide">
                                    Be part of a team that's at the forefront of redefining the real estate
                                    industry with creativity and forward-thinking.
                                </p>
                            </div>
                            <div className="text-center bg-gradient-to-r from-purple-500 to-pink-500 text-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
                                <div className="flex items-center justify-center mb-4">
                                    <img
                                        src="https://img.icons8.com/ios-filled/50/ffffff/health-insurance.png"
                                        alt="Benefits Icon"
                                    />
                                </div>
                                <h4 className="font-bold text-xl mb-2 tracking-wider">Comprehensive Benefits</h4>
                                <p className="text-base tracking-wide">
                                    We offer competitive salaries, health benefits, and generous paid time
                                    off, ensuring our employees are well taken care of.
                                </p>
                            </div>
                            <div className="text-center bg-gradient-to-r from-yellow-400 to-red-500 text-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
                                <div className="flex items-center justify-center mb-4">
                                    <img
                                        src={careerIcon}
                                        alt="Growth Icon"
                                    />
                                </div>
                                <h4 className="font-bold text-xl mb-2 tracking-wider">Career Growth</h4>
                                <p className="text-base tracking-wide">
                                    Unlock your potential with our professional development opportunities
                                    and climb the career ladder with us.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="mt-16 text-center">
                        <h2 className="text-2xl font-semibold text-gray-800">
                            Ready to Start?
                        </h2>
                        <p className="text-sm text-gray-500 mt-2">
                            If you’re excited to be part of a winning team, Arapti Real Estate is a great place to grow your career.
                        </p>
                        <button
                            onClick={() => navigate("/contact")}
                            className="mt-4 px-6 py-3 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700"
                        >
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Career;



















// import React, { useEffect, useState } from 'react';
// import UserHeader from '../../components/common/UserHeader';
// import { useNavigate } from 'react-router-dom';
// import ApplicationModal from '../../components/careerModal/ApplicationModal';

// const Career = () => {
//     const navigate = useNavigate();
//     const [showModal, setShowModal] = useState(false);
//     const [data, setData] = useState({});

//     const careerInfo = [
//         {
//             "id": 1,
//             "role": "Digital Marketing Associate",
//             "type": "Full-Time",
//             "location": "Bhubaneswar",
//             "job_desc" : "As a Digital Marketing Associate at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
//         },
//         {
//             "id": 2,
//             "role": "Marketing Manager",
//             "type": "Full-Time",
//             "location": "Bhubaneswar",
//             "job_desc" : "As a Marketing Manager at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
//         },
//         {
//             "id": 3,
//             "role": "Business Development Executive",
//             "type": "Full-Time",
//             "location": "Bhubaneswar",
//             "job_desc" : "As a BDE at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
//         },
//         {
//             "id": 4,
//             "role": "Telecaller",
//             "type": "Full-Time",
//             "location": "Bhubaneswar",
//             "job_desc" : "As a Telecaller at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
//         },
//         {
//             "id": 5,
//             "role": "HR Generalist",
//             "type": "Full-Time",
//             "location": "Bhubaneswar",
//             "job_desc" : "As a HR Generalist at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
//         },
//         {
//             "id": 6,
//             "role": "Project Coordinator",
//             "type": "Full-Time",
//             "location": "Bhubaneswar",
//             "job_desc" : "As a Project Coordinator at Arapti, you will be responsible for developing and executing strategic marketing plans that promote our real estate services and properties. You will work closely with the sales team to create campaigns that drive demand and generate leads. Your innovative thinking will be crucial in building our brand and expanding our market presence."
//         },
//     ]

//     const toggleModal = () => {
//         setShowModal(!showModal);
//     };
//     return (
//         <React.Fragment>
//             <UserHeader
//                 pageName="Career"
//                 mainText="Home"
//             />

//             {showModal
//                 &&
//                 (
//                     <ApplicationModal
//                         data={data}
//                         closeModal={toggleModal}
//                     />
//                 )
//             }

//             <div className="bg-white py-6">
//                 <div className="max-w-screen-xl mx-auto px-6">
//                     <div className="text-center">
//                         <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl">
//                             Join Our Team
//                         </h1>
//                         <p className="text-base text-gray-500 mt-4 tracking-wider">
//                             Start your career with a leading real estate company<br /> and help us shape the future of real estate.
//                         </p>
//                     </div>

//                     <div className="mt-6">
//                         <h2 className="text-2xl font-semibold text-gray-800">
//                             Open Positions
//                         </h2>
//                         <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//                             {
//                                 careerInfo?.map((data, i) => {
//                                     return (
//                                         <div
//                                             key={i}
//                                             onClick={() => {
//                                                 toggleModal()
//                                                 setData(data)
//                                             }}
//                                             className="border p-4 rounded-lg shadow hover:bg-[#DEFFCD]">
//                                             <h3 className="text-lg font-bold text-primaryColor tracking-wider">
//                                                 {data?.role}
//                                             </h3>
//                                             <div className='flex items-center justify-between'>
//                                                 <div>
//                                                     <p className="mt-1 text-sm font-semibold text-gray-800 tracking-wider">
//                                                         {data?.type}
//                                                     </p>
//                                                     <p className="mt-1 text-sm text-gray-600 tracking-wider">
//                                                         {data?.location}
//                                                     </p>
//                                                 </div>
//                                                 <button
//                                                     onClick={() => {
//                                                         toggleModal()
//                                                         setData(data)
//                                                     }}
//                                                     className='bg-blue-500 text-white px-4 py-1 rounded-md hover:scale-105 transition duration-100 ease-linear'>
//                                                     More Details
//                                                 </button>
//                                             </div>
//                                         </div>
//                                     )
//                                 })
//                             }
//                         </div>
//                     </div>

//                     <div className="mt-12">
//                         <h2 className="text-2xl font-semibold text-gray-800 tracking-wider">
//                             Why Work With Us?
//                         </h2>
//                         <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-6">
//                             <div className="text-center">
//                                 <div className="p-4 border rounded-lg shadow">
//                                     <h4 className="font-bold text-lg tracking-wider">
//                                         Innovative Environment
//                                     </h4>
//                                     <p className="mt-2 text-sm text-gray-600 tracking-wider">
//                                         Join a creative team that's committed to redefining the real estate industry.
//                                     </p>
//                                 </div>
//                             </div>
//                             <div className="text-center">
//                                 <div className="p-4 border rounded-lg shadow">
//                                     <h4 className="font-bold text-lg tracking-wider">
//                                         Employee Benefits
//                                     </h4>
//                                     <p className="mt-2 text-sm text-gray-600 tracking-wider">
//                                         Enjoy competitive salaries, health benefits, and paid time off.
//                                     </p>
//                                 </div>
//                             </div>
//                             <div className="text-center">
//                                 <div className="p-4 border rounded-lg shadow">
//                                     <h4 className="font-bold text-lg tracking-wider">
//                                         Growth Opportunities
//                                     </h4>
//                                     <p className="mt-2 text-sm text-gray-600 tracking-wider">
//                                         We provide professional development opportunities to help you reach your full potential.
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="mt-12 text-center">
//                         <h2 className="text-2xl font-semibold text-gray-800 tracking-wider">Ready to Start?</h2>
//                         <p className="mt-2 text-sm text-gray-500 tracking-wider">
//                             If you’re excited to be part of a winning team,<br /> Arapti Real Estate is a great place to grow your career.
//                         </p>

//                         <button
//                             onClick={() => navigate("/contact")}
//                             className="tracking-wider mt-4 px-6 py-3 bg-green-500 text-white font-semibold rounded hover:bg-green-600">
//                             Contact Us
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </React.Fragment>
//     )
// }

// export default Career
