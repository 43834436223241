import React, { useState } from 'react';
import axios from 'axios';

const ApplicationModal = ({ closeModal, data }) => {
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [imageFile, setImageFile] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        jobLetter: '',
        resume: null,
    });

    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            jobLetter: '',
            resume: null,
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        setImageFile(e.target.files[0]);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.phone || !imageFile) {
            alert('Please fill in all the required fields and upload your resume.');
            return;
        }

        setIsLoading(true);

        try {
            const formDataCloudinary = new FormData();
            formDataCloudinary.append('file', imageFile);
            formDataCloudinary.append('upload_preset', 'Resume');

            const cloudinaryResponse = await axios.post(
                'https://api.cloudinary.com/v1_1/dhysboq8w/image/upload',
                formDataCloudinary
            );

            const cloudinaryUrl = cloudinaryResponse?.data?.secure_url;
            console.log(cloudinaryUrl);

            setFormData(prevState => ({
                ...prevState,
                resume: cloudinaryUrl
            }));

            const obj = {
                name: formData?.name,
                email: formData?.email,
                phone: formData?.phone,
                jobLetter: formData?.jobLetter,
                resume: cloudinaryUrl,
                jobData: data
            }
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://arapti.onrender.com/real-estate/jobs/create-job-apply`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: obj
            };

            axios.request(config)
                .then((response) => {
                    console.log(response.data);
                    clearForm()
                    setIsLoading(false)
                    setSuccessModalOpen(true);

                })
                .catch((error) => {
                    setIsLoading(false)
                    console.log(error);
                });
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsLoading(false)
        }
    };

    console.log("datadata", data)

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-[#0b0a0a7a]">
                <div className="relative w-auto max-w-lg mx-auto my-6">
                    {/* Set a max height and overflow-y-auto on the content container */}
                    <div className="bg-white p-8 rounded-lg shadow-md overflow-y-auto" style={{ maxHeight: '80vh' }}>
                        <button
                            onClick={closeModal}
                            className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                            Job Details
                        </h2>

                        {/* Content fields */}
                        <div className='w-full space-y-2 mb-2 font-semibold border-2 border-primaryColor border-dashed rounded-md p-4'>
                            <div className='flex justify-between items-center tracking-wide border-b-2 pb-2'>
                                <div className='text-sm text-primaryTextColor'>
                                    Role
                                </div>
                                <div className='text-sm text-primaryColor'>
                                    {data?.role}
                                </div>
                            </div>

                            <div className='flex justify-between items-center tracking-wide border-b-2 pb-2'>
                                <div className='text-sm text-primaryTextColor'>
                                    Location
                                </div>
                                <div className='text-sm text-primaryColor'>
                                    {data?.location}
                                </div>
                            </div>

                            <div className='flex justify-between items-center tracking-wide border-b-2 pb-2'>
                                <div className='text-sm text-primaryTextColor'>
                                    Job Type
                                </div>
                                <div className='text-sm text-primaryColor'>
                                    {data?.type}
                                </div>
                            </div>

                            <div className='tracking-wide'>
                                <div className='text-sm text-primaryColor'>
                                    Job Description
                                </div>

                                <div className='text-sm text-primaryTextColor mt-2 text-justify'>
                                    {data?.job_desc}
                                </div>
                            </div>
                        </div>

                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Apply for the Position</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mt-4">
                                <input
                                    required
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Your Name"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-4">
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Your Email"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-4">
                                <input
                                    required
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Your Phone"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-4">
                                <textarea
                                    name="jobLetter"
                                    value={formData.jobLetter}
                                    onChange={handleChange}
                                    placeholder="Job Letter (Optional)"
                                    className="w-full h-32 px-4 py-2 border rounded-md resize-none focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="resume" className='text-secondaryTextColor'>Upload Resume in JPG fromat</label>
                                <input
                                    readOnly
                                    type="file"
                                    name="resume"
                                    onChange={handleImageChange}
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="flex justify-end mt-6">
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="px-4 py-2 mr-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    {isLoading ? "Loading..." : "Apply"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-[#0b0a0a7a]">
                <div className="relative w-auto max-w-lg mx-auto my-6">
                    <div className="bg-white p-8 rounded-lg shadow-md">
                        <button
                            onClick={closeModal}
                            className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800"
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                            Job Details
                        </h2>

                        <div className='w-full space-y-2 mb-2'>
                        <div className='flex justify-between items-center tracking-wide border-b-2 pb-2'>
                            <div className='text-md text-primaryTextColor'>
                                Role
                            </div>

                            <div className='text-md text-primaryColor'>
                                {data?.role}
                            </div>
                        </div>

                        <div className='flex justify-between items-center tracking-wide'>
                            <div className='text-md text-primaryTextColor'>
                                Location
                            </div>

                            <div className='text-md text-primaryColor'>
                                {data?.location}
                            </div>
                        </div>

                        <div className='flex justify-between items-center tracking-wide'>
                            <div className='text-md text-primaryTextColor'>
                                Job Type
                            </div>

                            <div className='text-md text-primaryColor'>
                                {data?.type}
                            </div>
                        </div>
                        </div>

                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                            Apply for the Position
                        </h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mt-4">
                                <input
                                    required
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Your Name"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-4">
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Your Email"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-4">
                                <input
                                    required
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Your Phone"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-4">
                                <textarea
                                    name="jobLetter"
                                    value={formData.jobLetter}
                                    onChange={handleChange}
                                    placeholder="Job Letter (Optional)"
                                    className="w-full h-32 px-4 py-2 border rounded-md resize-none focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="mt-2">
                                <label htmlFor="resume" className='text-secondaryTextColor'>Upload Resume in JPG fromat</label>
                                <input
                                    readOnly
                                    type="file"
                                    name="resume"
                                    onChange={handleImageChange}
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-500"
                                />
                            </div>
                            <div className="flex justify-end mt-6">
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="px-4 py-2 mr-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    {isLoading ? "Loading..." : "Apply"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
            {successModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-[#0b0a0a7a]">
                    <div className="relative w-auto max-w-lg mx-auto my-6">
                        <div className="bg-white p-8 rounded-lg shadow-md">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Application Submitted Successfully</h2>
                            <p>Your application has been successfully submitted.</p>
                            <div className="flex justify-end mt-6">
                                <button
                                    onClick={() => {
                                        setSuccessModalOpen(false);
                                        closeModal();
                                    }}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ApplicationModal;
