import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import flatIcon from '../../assets/home/SVG.png';
import duplexIcon from '../../assets/home/SVG (1).png';
import landIcon from '../../assets/home/SVG.png';
import { FaHome } from "react-icons/fa";

const PropertyCategories = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            easing: 'ease-in-out', 
            once: false, 
            mirror: true,
        });
    }, []);

    return (
        <React.Fragment>
            <div className="px-6 md:px-10 lg:px-16 mx-auto w-full mt-20 md:mt-24 lg:mt-28 text-center">
                <div className='mx-auto w-full max-w-7xl md:flex md:space-x-8 items-center'>
                    <div className='w-full md:w-[50%] flex flex-col items-center md:items-start mb-6 md:mb-0'>
                        <h2 className='text-primaryColor text-[16px] md:text-[18px] flex flex-row items-center space-x-3' data-aos="fade-right">
                            <FaHome />
                            <span>TRUSTED REAL ESTATE CARE</span>
                        </h2>
                        <h1 className="text-[30px] md:text-[40px] lg:text-[45px] mb-4 text-[#26232A]" data-aos="fade-right" data-aos-delay="200">
                            Properties Categories
                        </h1>
                    </div>
                    <div className="text-secondaryTextColor w-full md:w-[50%] text-sm md:text-md lg:text-lg flex flex-col items-center md:items-start" data-aos="fade-left">
                        <p>Our company provides a full range of services for the construction</p>
                        <p>of private houses and cottages since 19.</p>
                    </div>
                </div>

                {/* Categories Section */}
                <div className="flex flex-col md:flex-row md:space-x-6 justify-between items-center md:items-stretch mt-12">
                    {/* Flats */}
                    <div className="w-full md:w-1/3 mb-6 md:mb-0" data-aos="zoom-in">
                        <div className="shadow-lg rounded-lg w-full flex items-center justify-center p-8 md:p-12 lg:p-16">
                            <img src={flatIcon} alt="Flats" className="w-1/2 md:w-[40%] lg:w-[30%]" />
                        </div>
                        <h3 className="text-xl font-semibold mt-5">Flats</h3>
                        <p className="text-primaryColor">13 Properties</p>
                    </div>

                    {/* Duplex Homes */}
                    <div className="w-full md:w-1/3 mb-6 md:mb-0" data-aos="zoom-in" data-aos-delay="200">
                        <div className="shadow-lg rounded-lg w-full flex items-center justify-center p-8 md:p-12 lg:p-16">
                            <img src={duplexIcon} alt="Duplex Homes" className="w-1/2 md:w-[40%] lg:w-[30%]" />
                        </div>
                        <h3 className="text-xl font-semibold mt-5">Duplex Homes</h3>
                        <p className="text-primaryColor">15 Properties</p>
                    </div>

                    {/* Lands */}
                    <div className="w-full md:w-1/3" data-aos="zoom-in" data-aos-delay="400">
                        <div className="shadow-lg rounded-lg w-full flex items-center justify-center p-8 md:p-12 lg:p-16">
                            <img src={landIcon} alt="Lands" className="w-1/2 md:w-[40%] lg:w-[30%]" />
                        </div>
                        <h3 className="text-xl font-semibold mt-5">Lands</h3>
                        <p className="text-primaryColor">16 Properties</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyCategories;
