import React from 'react';
import Sidebar from '../components/common/Sidebar';
import AuthNavbar from '../components/common/AuthNavbar';

const DashboardLayout = ({ children }) => (
    <React.Fragment>
        <AuthNavbar />
        <div style={{ display: 'flex' }}>
            <div className='lg:w-[20%] h-[100vh]'>
                <Sidebar />
            </div>
            <main className='lg:w-[80%] w-[100%] h-[100vh] overflow-y-scroll'>
                {children}
            </main>
        </div>
    </React.Fragment>
);

export default DashboardLayout;
