import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../../assets/home/home3-about-thumb.png';
import img2 from '../../assets/home/Frame 14084.svg';
import img3 from '../../assets/home/Frame 14085.svg';
import { FaHome } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const TwoSectionLayout = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            easing: 'ease-in-out', 
            once: true,
        });
    }, []);
    
    const navigate = useNavigate();

    return (
        <div className="flex flex-col md:flex-row items-center md:items-stretch px-4 md:px-8 py-8 gap-y-16 md:gap-y-0 gap-x-0 md:gap-x-8 lg:gap-x-12 bg-gray-100">
            {/* First Section - Image */}
            <div
                className="md:w-1/2 w-full mb-8 md:mb-0 flex justify-center max-h-[28rem] lg:max-h-[32rem]"
                data-aos="fade-right"
            >
                <img
                    src={img1}
                    alt="img"
                    className="w-full h-full object-contain md:object-cover animate-pulse"
                />
            </div>

            {/* Second Section - Text Content */}
            <div
                className="md:w-1/2 w-full flex flex-col justify-center"
                data-aos="fade-left"
            >
                <h1 className="flex gap-2 items-center text-primaryColor font-medium uppercase text-sm md:text-md tracking-wide">
                    <FaHome size={20} /> <span>Trusted Real Estate Care</span>
                </h1>
                <h2 className="text-xl md:text-3xl lg:text-4xl font-bold my-4 md:my-6 tracking-wide md:tracking-wider text-primaryTextColor">
                    Dream Living Spaces Setting New Build
                </h2>
                <p className="text-secondaryTextColor tracking-wide font-medium text-sm md:text-md mb-6">
                    At Dream Living Spaces, we believe that your home is more than just a place to live – it’s where life unfolds.
                </p>

                <div className="flex flex-col md:flex-row md:justify-between mb-6 w-full lg:w-4/5">
                    <div className="flex items-center mb-4 md:mb-0" data-aos="zoom-in">
                        <img src={img3} alt="Duplex Houses" className="mr-2 w-10 h-10 md:w-12 md:h-12" />
                        <div className="text-start text-md font-medium text-primaryTextColor">
                            Perfect Duplex Houses
                        </div>
                    </div>
                    <div className="flex items-center" data-aos="zoom-in">
                        <img src={img2} alt="Support Team" className="mr-2 w-10 h-10 md:w-12 md:h-12" />
                        <div className="text-start text-md font-medium text-primaryTextColor">
                            Friendly Support Team
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row justify-between gap-4">
                    <div data-aos="fade-up">
                        <h3 className="text-md md:text-lg font-semibold tracking-wide text-primaryTextColor">Construction</h3>
                        <p className="text-secondaryTextColor tracking-wide font-medium text-sm md:text-md">
                            Planning, scheduling, and managing resources to ensure timely completion.
                        </p>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200">
                        <h3 className="text-md md:text-lg font-semibold tracking-wide text-primaryTextColor">Architecture</h3>
                        <p className="text-secondaryTextColor tracking-wide font-medium text-sm md:text-md">
                            Detailed plans used for constructing buildings, including exterior designs.
                        </p>
                    </div>
                </div>

                <div className="flex justify-center md:justify-start mt-6" data-aos="fade-up">
                    <button className="text-white bg-primaryColor px-4 py-2 md:px-6 md:py-3 rounded-md" onClick={()=>{navigate('/about')}}>
                        More About Us
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TwoSectionLayout;















// import React, { useEffect } from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import img1 from '../../assets/home/home3-about-thumb.png';
// import img2 from '../../assets/home/Frame 14084.svg';
// import img3 from '../../assets/home/Frame 14085.svg';
// import { FaHome } from "react-icons/fa";
// import { useNavigate } from 'react-router-dom';

// const TwoSectionLayout = () => {
//     useEffect(() => {
//         AOS.init({
//             duration: 1000, 
//             easing: 'ease-in-out', 
//             once: true,
//         });
//     }, []);
//     const navigate = useNavigate();
//     return (
//         <div className="flex flex-col md:flex-row items-center md:items-stretch px-6 py-8 gap-y-44 bg-gray-100">
//             {/* First Section - Image */}
//             <div
//                 className="md:w-1/2 w-full mb-8 md:mb-0 flex justify-center max-h-[32rem]"
//                 data-aos="fade-right"
//             >
//                 <img
//                     src={img1}
//                     alt="img"
//                     className="w-full h-full object-contain animate-pulse"
//                 />
//             </div>

//             {/* Second Section - Text Content */}
//             <div
//                 className="md:w-1/2 w-full md:pl-8 flex flex-col justify-center max-h-[32rem]"
//                 data-aos="fade-left"
//             >
//                 <h1 className="flex gap-2 items-center text-primaryColor font-medium uppercase text-md tracking-wide">
//                     <FaHome size={20} /> <span>Trusted Real Estate Care</span>
//                 </h1>
//                 <h2 className="text-2xl md:text-4xl font-bold my-6 tracking-wider text-primaryTextColor">
//                     Dream Living Spaces Setting New Build
//                 </h2>
//                 <p className="text-secondaryTextColor tracking-wider font-medium text-md mt-2 mb-6">
//                    At Dream Living Spaces, we believe that your home is more than just a place to live – it’s where life unfolds. As pioneers in crafting innovative
//                 </p>

//                 <div className="flex flex-col md:flex-row md:justify-between mb-6 w-full lg:w-4/5">
//                     <div className="flex items-center mb-4 md:mb-0" data-aos="zoom-in">
//                         <div>
//                             <img src={img3} alt="Duplex Houses" className="mr-2" />
//                         </div>
//                         <div className="text-start text-lg font-medium text-primaryTextColor">
//                             Perfect Duplex Houses
//                         </div>
//                     </div>
//                     <div className="flex items-center" data-aos="zoom-in">
//                         <div>
//                             <img src={img2} alt="Support Team" className="mr-2" />
//                         </div>
//                         <div className="text-start text-lg font-medium text-primaryTextColor">
//                             Friendly Support Team
//                         </div>
//                     </div>
//                 </div>

//                 <div className="flex flex-col md:flex-row justify-between gap-6">
//                     <div data-aos="fade-up">
//                         <h3 className="text-lg font-semibold tracking-wider text-primaryTextColor">Construction</h3>
//                         <p className="text-secondaryTextColor tracking-wider font-medium text-md">
//                         Building and Infrastructure Planning, scheduling, and managing resources to ensure timely completion
//                         </p>
//                     </div>
//                     <div data-aos="fade-up" data-aos-delay="200">
//                         <h3 className="text-lg font-semibold tracking-wider text-primaryTextColor">Architecture</h3>
//                         <p className="text-secondaryTextColor tracking-wider font-medium text-md">
//                         A detailed plan or drawing used as a guide for constructing buildings.,A drawing that shows one side of a building, typically the exterior.
//                         </p>
//                     </div>
//                 </div>

//                 <div className="flex justify-center lg:justify-start mt-6" data-aos="fade-up">
//                     <button className="text-white bg-primaryColor px-6 py-3 rounded-md" onClick={()=>{navigate('/about')}}>
//                         More About Us
//                     </button>
//                 </div>

//             </div>
//         </div>
//     );
// };

// export default TwoSectionLayout;
