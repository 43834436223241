import React from 'react'
import email_icon from '../../assets/home/email-icon.svg'
import phone_icon from '../../assets/home/phone-icon.svg'
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from 'react-router-dom';

const TopNavbar = () => {
    return (
        <div className='hidden md:block bg-[#062F23] text-white tracking-wider px-10 py-1'>
            <div className='flex items-center justify-between'>
                <div className='md:flex md:flex-row flex-col items-center gap-10'>
                    <div className='flex items-center gap-2'>
                        <img
                            src={email_icon}
                            alt="email_icon"
                            className='h-5 w-auto'
                        />

                        <p className='text-xs'>
                            araptirealestatepvtltd89@gmail.com
                        </p>
                    </div>

                    <div className='flex items-center gap-2'>
                        <img
                            src={phone_icon}
                            alt="email_icon"
                            className='h-5 w-auto'
                        />
                        <p className='text-xs'>
                            +91 9827844414
                        </p>
                    </div>
                </div>

                <div className='hidden md:flex items-center gap-3'>
                    <Link
                        to="https://www.facebook.com/profile.php?id=61565248872857&mibextid=ZbWKwL"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaFacebookF size={18} className="cursor-pointer" />
                    </Link>

                    <Link
                        to="https://www.instagram.com/arepl2024?igsh=MTJkZGtndjdiZ3J1bQ=="
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <RiInstagramFill size={18} className="cursor-pointer" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default TopNavbar