import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import plotImage1 from "../../assets/home/image (1).png";
import plotImage2 from "../../assets/home/image 7.png";
import plotImage3 from "../../assets/home/image.png";
import { FaHome } from "react-icons/fa";
import axios from "axios";
import LandsCard from "../common/LandsCard";
import { useNavigate } from "react-router-dom";

const Ourlands = () => {
    const navigate = useNavigate();
    const [allLands, setAllLands] = useState([]);

    useEffect(() => {
        getAllLands();

        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
            mirror: true,
        });
    }, []);

    const properties = [
        { id: 1, title: "Plot No - 568", location: "Near Tamando, Bhubaneswar, Odisha, 751030", size: "3450 Square Ft", image: plotImage1 },
        { id: 2, title: "Plot No - 212", location: "Near Tamando, Bhubaneswar, Odisha, 751030", size: "3450 Square Ft", image: plotImage2 },
        { id: 3, title: "Plot No - 568", location: "Near Tamando, Bhubaneswar, Odisha, 751030", size: "3450 Square Ft", image: plotImage3 },
        { id: 4, title: "Plot No - 568", location: "Near Tamando, Bhubaneswar, Odisha, 751030", size: "3450 Square Ft", image: plotImage1 },
        { id: 5, title: "Plot No - 212", location: "Near Tamando, Bhubaneswar, Odisha, 751030", size: "3450 Square Ft", image: plotImage2 },
        { id: 6, title: "Plot No - 568", location: "Near Tamando, Bhubaneswar, Odisha, 751030", size: "3450 Square Ft", image: plotImage3 },
    ];

    const getAllLands = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://arapti.onrender.com/real-estate/lands/get-all-lands',
            headers: {},
            data: ''
        };

        // setLandIsLoading(true);
        axios.request(config)
            .then((response) => {
                console.log('Lands data:', response?.data); // Debugging line
                setAllLands(response?.data);
                // setLandIsLoading(false);
            })
            .catch((error) => {
                console.log('Error fetching lands:', error);
                // setLandIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <div className="bg-gray-100 p-6 sm:p-10 mx-auto mt-8">
                {/* Header Section */}
                <div className="mb-8" data-aos="fade-up">
                    <div className='flex flex-col items-center justify-center gap-y-4 sm:gap-y-6 md:gap-y-8' data-aos="fade-up">
                        <p className="text-[15px] text-primaryColor sm:text-[16px] md:text-[17px] flex items-center space-x-3">
                            <FaHome />
                            <span>TRUSTED REAL ESTATE CARE</span>
                        </p>
                        <h1 className="text-primaryTextColor text-[30px] sm:text-[35px] md:text-[45px] font-bold mb-2 text-center">
                            Our Lands
                        </h1>
                    </div>

                    <div className="px-12 my-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
                        {
                            allLands
                                .slice(0, 3)
                                .map((land, index) => (
                                    <LandsCard
                                        key={index}
                                        land={land}
                                    />
                                ))}
                    </div>

                    {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8" data-aos="fade-up" data-aos-delay="100">
                        {properties.map((property, index) => (
                            <div key={property.id} className="bg-white rounded-xl shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300" data-aos="fade-up" data-aos-delay={index * 100}>
                                <div className="relative">
                                    <img
                                        src={property.image}
                                        alt={property.title}
                                        className="h-48 w-full object-cover"
                                    />
                                    <div className="absolute top-4 left-4 bg-primaryColor text-white text-sm font-medium px-3 py-1 rounded-full">
                                        Featured
                                    </div>
                                </div>

                                <div className="p-4">
                                    <h3 className="text-lg font-semibold text-gray-800">{property.title}</h3>
                                    <div className="flex items-center text-gray-500 mt-2">
                                        <FaLocationDot className="h-5 w-5 mr-1 text-primaryColor" />
                                        {property.location}
                                    </div>
                                    <div className="flex items-center text-gray-500 mt-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 mr-1 text-primaryColor"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                        >
                                            <path d="M4 2a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V3a1 1 0 00-1-1H4zm11 2v2h-3V4h3zM7 4v2H4V4h3zm7 4v2h-3V8h3zM7 8v2H4V8h3zm7 4v2h-3v-2h3zM7 12v2H4v-2h3zm-3 6v-2h10v2H4z" />
                                        </svg>
                                        {property.size}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}

                    <button
                        onClick={() => navigate('/properties')}
                    className="bg-primaryColor text-white font-semibold px-4 py-2 sm:px-6 sm:py-2 rounded-lg hover:bg-blue-700 mt-4">
                        View More
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Ourlands;
