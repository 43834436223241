import React, { useState } from 'react'
import UserHeader from '../../components/common/UserHeader'
import Lands from './Lands'
import Buildings from '../users/Buildings'
import TrendingProperties from '../../components/common/TrendingProperties'
import Ourlands from '../../components/common/Ourlands'
import BrowseProperties from '../../components/common/BrowseProperties'

const Properties = () => {
    const [iseActive, setIsActive] = useState('Buildings')

    return (
        <>
            <UserHeader
                pageName="Our Properties"
                mainText="Home" />
                {/* <BrowseProperties/> */}
                <TrendingProperties/>
                {/* <Ourlands/> */}
        </>
    )
}
export default Properties