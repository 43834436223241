import React, { useEffect } from "react";
import UserHeader from "../../../components/common/UserHeader";
import img1 from "../../../assets/internal.png";
import img2 from "../../../assets/external.png";
import img3 from "../../../assets/commEng.png";
import img4 from "../../../assets/onlineNet.png";
import img5 from "../../../assets/proffAss.png";
import img6 from "../../../assets/alumni.png";

const Community = () => {
  
  
  return (
    <React.Fragment>
      <UserHeader
        pageName="Community"
        mainText="Home" />

      <div className="p-10">
        <h1 className="text-[#B52E2B] text-[30px] tracking-wide font-bold text-center">
          Where Unity Fosters Strength
        </h1>

        <p className="text-[16px] tracking-wider text-center text-[#8B8989] mt-2">
          Networking and community-building are essential components of our
          approach at Arapti. We recognize the value of fostering
          connections, both within our organization and with the broader
          community, to create a network of support, collaboration, and shared
          success. Here's how we prioritize networking and community-building:
        </p>

        <div className="flex flex-col gap-8 mt-6">
          <div className="flex flex-col md:flex-row items-center gap-10">
            <img src={img1} alt="Description" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide text-justify">
                Internal Networking :
              </h2>
              <p className="text-justify tracking-wider text-[#8B8989] text-lg">
                Within our organization, we cultivate a culture of collaboration
                and teamwork, encouraging employees to connect, communicate, and
                collaborate across departments and teams. We facilitate internal
                networking opportunities such as team-building activities,
                cross-functional projects, and knowledge-sharing sessions to
                foster strong relationships and a sense of unity among our staff.
              </p>
            </div>
          </div>


          <div className="flex flex-col md:flex-row-reverse items-center gap-10">
            <img src={img2} alt="Description" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
                External Networking :
              </h2>
              <p className="text-justify tracking-wider text-[#8B8989] text-lg">
                We actively engage with external stakeholders, including clients,
                partners, suppliers, and industry peers, to build mutually
                beneficial relationships and expand our professional network. We
                participate in industry events, conferences, and networking forums
                to connect with key stakeholders, exchange ideas, and stay abreast
                of industry trends and developments.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-10">
            <img src={img3} alt="Description" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
                Community Engagement :
              </h2>
              <p className="text-justify tracking-wider text-[#8B8989] text-lg">
                We are committed to being active contributors to the communities
                in which we operate. Through corporate social responsibility
                initiatives, volunteer programs, and community outreach efforts,
                we support local organizations, charities, and causes that align
                with our values and mission. By giving back to the community, we
                strengthen our connections and build goodwill with residents and
                stakeholders.
              </p>
            </div>
          </div>


          <div className="flex flex-col md:flex-row-reverse items-center gap-10">
            <img src={img4} alt="Description" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
                Online Networking :
              </h2>
              <p className="text-justify tracking-wider text-[#8B8989] text-lg">
                In today's digital age, online networking platforms play a vital
                role in connecting professionals and fostering collaboration. We
                leverage social media platforms, professional networking sites,
                and online forums to connect with industry peers, share insights
                and expertise, and build relationships with potential clients and
                partners. Our online presence helps us stay connected and engaged
                with our network, even in virtual settings.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-10">
            <img src={img5} alt="Description" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
                Professional Associations :
              </h2>
              <p className="text-justify tracking-wider text-[#8B8989] text-lg">
                We actively participate in professional associations, industry
                groups, and trade organizations related to real estate,
                construction, and property development. Membership in these
                associations provides valuable networking opportunities, access to
                industry resources and best practices, and opportunities for
                professional development and growth. By being actively involved in
                professional associations, we strengthen our network and enhance
                our industry knowledge and expertise.
              </p>
            </div>
          </div>


          <div className="flex flex-col md:flex-row-reverse items-center gap-10">
            <img src={img6} alt="Description" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
                Alumni Networks :
              </h2>
              <p className="text-justify tracking-wider text-[#8B8989] text-lg">
                We maintain connections with former employees, clients, and
                partners through alumni networks and alumni events. Alumni
                networks provide valuable opportunities to reconnect with past
                colleagues, clients, and collaborators, exchange updates and
                insights, and tap into a pool of talent and expertise for future
                opportunities. Overall, networking and community-building are
                integral parts of our strategy at Arapti. By fostering strong
                connections, both internally and externally, we strengthen our
                organization, build trust and credibility, and create
                opportunities for growth and success in the dynamic real estate
                industry.
              </p>
            </div>
          </div>
        </div>


        {/* <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-1">
            <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide text-justify">
              Internal Networking :
            </h2>
            <p className="text-justify tracking-wider text-[#8B8989] text-lg">
              Within our organization, we cultivate a culture of collaboration
              and teamwork, encouraging employees to connect, communicate, and
              collaborate across departments and teams. We facilitate internal
              networking opportunities such as team-building activities,
              cross-functional projects, and knowledge-sharing sessions to
              foster strong relationships and a sense of unity among our staff.
            </p>
          </div>

          <div className="flex flex-col gap-1 ">
            <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
              External Networking :
            </h2>
            <p className="text-justify tracking-wider text-[#8B8989] text-lg">
              We actively engage with external stakeholders, including clients,
              partners, suppliers, and industry peers, to build mutually
              beneficial relationships and expand our professional network. We
              participate in industry events, conferences, and networking forums
              to connect with key stakeholders, exchange ideas, and stay abreast
              of industry trends and developments.
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
              Community Engagement :
            </h2>
            <p className="text-justify tracking-wider text-[#8B8989] text-lg">
              We are committed to being active contributors to the communities
              in which we operate. Through corporate social responsibility
              initiatives, volunteer programs, and community outreach efforts,
              we support local organizations, charities, and causes that align
              with our values and mission. By giving back to the community, we
              strengthen our connections and build goodwill with residents and
              stakeholders.
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
              Online Networking :
            </h2>
            <p className="text-justify tracking-wider text-[#8B8989] text-lg">
              In today's digital age, online networking platforms play a vital
              role in connecting professionals and fostering collaboration. We
              leverage social media platforms, professional networking sites,
              and online forums to connect with industry peers, share insights
              and expertise, and build relationships with potential clients and
              partners. Our online presence helps us stay connected and engaged
              with our network, even in virtual settings.
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
              Professional Associations :
            </h2>
            <p className="text-justify tracking-wider text-[#8B8989] text-lg">
              We actively participate in professional associations, industry
              groups, and trade organizations related to real estate,
              construction, and property development. Membership in these
              associations provides valuable networking opportunities, access to
              industry resources and best practices, and opportunities for
              professional development and growth. By being actively involved in
              professional associations, we strengthen our network and enhance
              our industry knowledge and expertise.
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <h2 className="text-[#B52E2B] font-semibold text-[20px] tracking-wide">
              Alumni Networks :
            </h2>
            <p className="text-justify tracking-wider text-[#8B8989] text-lg">
              We maintain connections with former employees, clients, and
              partners through alumni networks and alumni events. Alumni
              networks provide valuable opportunities to reconnect with past
              colleagues, clients, and collaborators, exchange updates and
              insights, and tap into a pool of talent and expertise for future
              opportunities. Overall, networking and community-building are
              integral parts of our strategy at Arapti. By fostering strong
              connections, both internally and externally, we strengthen our
              organization, build trust and credibility, and create
              opportunities for growth and success in the dynamic real estate
              industry.
            </p>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Community;
