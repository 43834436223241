import React, { useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const BlogForm = ({ blog, onSubmit, loading }) => {
  const [formData, setFormData] = useState(blog || {
    blogImage: "",
    category: "",
    title: "",
    authorName: "",
    authorImage: 'https://cdn3.iconfinder.com/data/icons/3d-printing-icon-set/512/User.png',
    paragraphs: [""],
    sortParagraph: ""
  });
  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formDataCloudinary = new FormData();
      formDataCloudinary.append('file', file);
      formDataCloudinary.append('upload_preset', 'Arapti Buildings');

      setUploading(true);
      try {
        const cloudinaryResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/dhysboq8w/image/upload`,
          formDataCloudinary
        );

        setFormData(prevState => ({
          ...prevState,
          blogImage: cloudinaryResponse.data.secure_url
        }));
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleParagraphChange = (index, value) => {
    const newParagraphs = [...formData.paragraphs];
    newParagraphs[index] = value;
    setFormData(prevState => ({
      ...prevState,
      paragraphs: newParagraphs
    }));
  };

  const addParagraph = () => {
    setFormData(prevState => ({
      ...prevState,
      paragraphs: [...prevState.paragraphs, ""]
    }));
  };

  const deleteParagraph = (index) => {
    const newParagraphs = formData.paragraphs.filter((_, i) => i !== index);
    setFormData(prevState => ({
      ...prevState,
      paragraphs: newParagraphs
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      createdDate: new Date().toISOString()
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 h-[400px] overflow-y-scroll">
      <div>
        <label htmlFor="blogImage" className="block text-sm font-medium text-gray-700">
          Blog Image
        </label>
        <input
          id="blogImage"
          type="file"
          onChange={handleFileChange}
          className="w-full p-2 border rounded"
          accept="image/*"
          disabled={loading || uploading}
        />
        {uploading && <p>Uploading image...</p>}
        {formData.blogImage && (
          <img src={formData.blogImage} alt="Blog" className="w-full h-64 object-cover rounded mt-2" />
        )}
      </div>
      
      <div>
        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
          Category
        </label>
        <input
          id="category"
          type="text"
          name="category"
          value={formData.category}
          onChange={handleChange}
          placeholder="Category"
          className="w-full p-2 border rounded"
          required
          disabled={loading || uploading}
        />
      </div>
      
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
          Title
        </label>
        <input
          id="title"
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Title"
          className="w-full p-2 border rounded"
          required
          disabled={loading || uploading}
        />
      </div>
      
      <div>
        <label htmlFor="authorName" className="block text-sm font-medium text-gray-700">
          Author Name
        </label>
        <input
          id="authorName"
          type="text"
          name="authorName"
          value={formData.authorName}
          onChange={handleChange}
          placeholder="Author Name"
          className="w-full p-2 border rounded"
          required
          disabled={loading || uploading}
        />
      </div>
      
      {formData.paragraphs.map((paragraph, index) => (
        <div key={index} className="relative">
          <label htmlFor={`paragraph${index}`} className="block text-sm font-medium text-gray-700">
            Paragraph {index + 1}
          </label>
          <textarea
            id={`paragraph${index}`}
            value={paragraph}
            onChange={(e) => handleParagraphChange(index, e.target.value)}
            placeholder={`Paragraph ${index + 1}`}
            className="w-full p-2 border rounded"
            disabled={loading || uploading}
          />
          <button
            type="button"
            onClick={() => deleteParagraph(index)}
            className="absolute top-0 right-0 mt-1 mr-1 text-red-500 hover:text-red-700"
            disabled={loading || uploading}
          >
            X
          </button>
        </div>
      ))}
      
      <button
        type="button"
        onClick={addParagraph}
        className="text-white bg-blue-500 px-4 py-1 rounded"
        disabled={loading || uploading}
      >
        Add More Paragraph
      </button>
      
      <div>
        <label htmlFor="sortParagraph" className="block text-sm font-medium text-gray-700">
          Short Paragraph
        </label>
        <textarea
          id="sortParagraph"
          name="sortParagraph"
          value={formData.sortParagraph}
          onChange={handleChange}
          placeholder="Short Paragraph"
          className="w-full p-2 border rounded"
          disabled={loading || uploading}
        />
      </div>
      
      <button
        type="submit"
        className="w-full bg-blue-500 text-white p-2 rounded"
        disabled={loading || uploading}
      >
        {loading ? <CircularProgress size={24} /> : blog ? 'Update Blog' : 'Create Blog'}
      </button>
    </form>
  );
};

export default BlogForm;
