import React, { useState, useEffect } from 'react';
import logoGif from "../assets/AraptiLogoGif.gif";
import bgImage from "../assets/firstRenderBg.jpg";
import startPage from "../assets/AraptiStartingPage.gif";
import startPagephone from '../assets/AraptiStartingPageforphone.gif'
const AuthProvider = ({ children }) => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const firstRenderingGif = () => {
    return (
      <div className='w-full h-screen flex items-center justify-center bg-black'>
        <img
          className='hidden md:block w-full h-full object-cover'
          src={startPage}
          alt="logo-gif"
        />
        <img
          className='block md:hidden w-full h-full object-cover'
          src={startPagephone}
          alt="logo-gif"
        />
      </div>


      // <div
      //   className='w-full h-screen flex justify-center items-center relative overflow-hidden'
      //   style={{
      //     backgroundImage: `url(${bgImage})`,
      //     backgroundSize: 'cover',
      //     backgroundPosition: 'center',
      //   }}
      // >
      //   <div
      //     className='absolute inset-0'
      //     style={{
      //       backgroundColor: 'rgba(255, 255, 255, 0.5)',
      //     }}
      //   />
      //   <img
      //     className='relative z-10 w-72 h-auto object-cover'
      //     src={logoGif}
      //     alt="logo-gif"
      //   />
      // </div>
    );
  };

  return (
    <React.Fragment>
      {showLoading ? firstRenderingGif() : children}
    </React.Fragment>
  );
};

export default AuthProvider;















// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const AuthProvider = ({ children }) => {
//   // const navigate = useNavigate();
//   // const [isAuthenticated, setIsAuthenticated] = useState(false);

//   // useEffect(() => {
//   //   const adminInfo = localStorage.getItem('adminInfo');
//   //   if (adminInfo) {
//   //     setIsAuthenticated(true);
//   //   }
//   // }, []);

//   // useEffect(() => {
//   //   if (isAuthenticated) {
//   //     navigate('/dashboard');
//   //   } else {
//   //     // navigate('/login');
//   //     return
//   //   }
//   // }, [isAuthenticated, navigate]);

//   return (
//     <React.Fragment>
//       {children}
//     </React.Fragment>
//   )
// }

// export default AuthProvider
