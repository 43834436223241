import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineShareLocation } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const LandsCard = ({ land }) => {
  const navigate = useNavigate();

  // console.log("allLands", land);

  return (
    <React.Fragment>
      <div
        onClick={() => navigate(`/property/land-details/${land?._id}`)}
        className="bg-white cursor-pointer rounded-xl shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300"
      >
        {/* Image Section */}
        <div className="relative">
          <img
            src={land?.propertyImages[0]}
            alt="land-img"
            className="h-48 w-full object-cover"
          />
          {/* Featured Badge */}
          <div className="absolute top-4 left-4 bg-primaryColor text-white text-sm font-medium px-3 py-1 rounded-full">
            {land?.financialInfo?.sellingPrice}
          </div>
        </div>

        {/* Details Section */}
        <div className="p-4">
          <h3 className="text-lg font-semibold text-gray-800">
            {land?.generalInfo?.propertyName}
          </h3>
          <div className="flex items-center text-gray-500 mt-2">
            <FaLocationDot size={22} className="mr-2 text-primaryColor" />
            {land?.locationDetails?.address}
          </div>
          <div className="flex items-center text-gray-500 mt-2">
            <MdOutlineShareLocation
              size={22}
              className="mr-2 text-primaryColor"
            />
            {land?.physicalCharacteristics?.totalArea}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandsCard;
