import image1 from "../../../assets/home/discover.jpeg";
import image2 from "../../../assets/home/property_setting.jpeg";
import image3 from "../../../assets/home/investment.jpeg";
import image4 from "../../../assets/home/plot_sale.jpeg";

export const InvestmentServices = [
    {
        image: image1,
        title: "DISCOVER VERIFIED PROPERTIES",
        path: "/discover-properties",
        para: "At Arapti, we understand the importance of trust and transparency when it comes to finding your perfect ....."
    },
    {
        image: image2,
        title: "PROPERTY SELLING",
        path: "/selling-service",
        para: "Our selling services are designed to help you sell your property quickly and at the best possible price. Here's how we can assist you"
    },
    {
        image: image4,
        title: "PLOT SALE SUPPORT",
        path: "/plot-sale-support",
        para: "At Arapti, we understand the importance of trust and transparency when it comes to finding your perfect ....."
    },
];

export const PropertySelling = [
    {
        image: image1,
        title: "DISCOVER VERIFIED PROPERTIES",
        path: "/discover-properties",
        para: "At Arapti, we understand the importance of trust and transparency when it comes to finding your perfect ....."
    },
    {
        image: image3,
        title: "INVESTMENT",
        path: "/investment-services",
        para: "Our investment services are tailored to help you identify and capitalize on lucrative investment opportunities in ...."
    },
    {
        image: image4,
        title: "PLOT SALE SUPPORT",
        path: "/plot-sale-support",
        para: "At Arapti, we understand the importance of trust and transparency when it comes to finding your perfect ....."
    },
];

export const DiscoverServices = [
    {
        image: image2,
        title: "PROPERTY SELLING",
        path: "/selling-service",
        para: "Our selling services are designed to help you sell your property quickly and at the best possible price. Here's how we can assist you"
    },
    {
        image: image3,
        title: "INVESTMENT",
        path: "/investment-services",
        para: "Our investment services are tailored to help you identify and capitalize on lucrative investment opportunities in ...."
    },
    {
        image: image4,
        title: "PLOT SALE SUPPORT",
        path: "/plot-sale-support",
        para: "At Arapti, we understand the importance of trust and transparency when it comes to finding your perfect ....."
    },
];

export const SuportServices = [
    {
        image: image1,
        title: "DISCOVER VERIFIED PROPERTIES",
        path: "/discover-properties",
        para: "At Arapti, we understand the importance of trust and transparency when it comes to finding your perfect ....."
    },
    {
        image: image2,
        title: "PROPERTY SELLING",
        path: "/selling-service",
        para: "Our selling services are designed to help you sell your property quickly and at the best possible price. Here's how we can assist you"
    },
    {
        image: image3,
        title: "INVESTMENT",
        path: "/investment-services",
        para: "Our investment services are tailored to help you identify and capitalize on lucrative investment opportunities in ...."
    }
];
