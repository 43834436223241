import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const LandsDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [landsFullData, setLandsFullData] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    getLandById();
  }, []);

  const getLandById = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://arapti.onrender.com/real-estate/lands/get-land-by-id/${params?.id}`,
      headers: {},
      data: "",
    };

    axios
      .request(config)
      .then((response) => {
        console.log("response.data", response.data);
        setLandsFullData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNext = () => {
    setCurrentImage(
      (prevImage) =>
        (prevImage + 1) % (landsFullData?.propertyImages?.length || 1)
    );
  };

  const handlePrev = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0
        ? (landsFullData?.propertyImages?.length || 1) - 1
        : prevImage - 1
    );
  };

  const renderDetailItem = (label, value) => (
    <div className="flex justify-between mb-2">
      <span className="font-semibold">{label}</span>
      <span>{value}</span>
    </div>
  );

  if (!landsFullData) {
    return (
      <div className="h-[30rem] text-xl tracking-wide flex justify-center items-center">
        Loading...
      </div>
    );
  }

  const {
    generalInfo,
    locationDetails,
    physicalCharacteristics,
    financialInfo,
    utilities,
    propertyImages = [],
  } = landsFullData;

  return (
    <div className="container mx-auto p-6">
      {/* Carousel */}
      {propertyImages.length > 0 ? (
        <div className="relative w-full lg:h-[28rem] sm:h-[20rem] h-[13rem]">
          <img
            src={propertyImages[currentImage]}
            alt={`Property Image ${currentImage + 1}`}
            className="w-full h-full object-cover rounded-md"
          />
          {propertyImages.length > 1 && (
            <>
              <button
                onClick={handlePrev}
                className="absolute text-sm sm:text-lg left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-1 rounded-full"
              >
                Prev
              </button>
              <button
                onClick={handleNext}
                className="absolute text-sm sm:text-lg right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-1 rounded-full"
              >
                Next
              </button>
            </>
          )}
        </div>
      ) : (
        <div>No images available</div>
      )}

      {/* Property Info in Cards */}
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4 capitalize">
        {/* General Info */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-2xl font-bold mb-4 text-primaryBtnColor">
            {generalInfo?.propertyName}
          </h2>
          {renderDetailItem("Property Type", generalInfo?.propertyType)}
          {renderDetailItem("Current Status", generalInfo?.currentStatus)}
        </div>

        {/* Location Details */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h3 className="text-xl font-semibold mb-4 text-primaryColor">
            Location Details
          </h3>
          {renderDetailItem("Address", locationDetails?.address)}
          {renderDetailItem("City", locationDetails?.city)}
          {renderDetailItem("State", locationDetails?.state)}
          {renderDetailItem("ZIP Code", locationDetails?.zipCode)}
          {renderDetailItem("Country", locationDetails?.country)}
        </div>

        {/* Physical Characteristics */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h3 className="text-xl font-semibold mb-4 text-primaryColor">
            Physical Characteristics
          </h3>
          {renderDetailItem(
            "Total Area",
            `${physicalCharacteristics?.totalArea} sq ft`
          )}
          {renderDetailItem(
            "Terrain Type",
            physicalCharacteristics?.terrainType
          )}
          {renderDetailItem("Soil Type", physicalCharacteristics?.soilType)}
          {renderDetailItem("Road Access", physicalCharacteristics?.roadAccess)}
        </div>

        {/* Financial Info */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h3 className="text-xl font-semibold mb-4 text-primaryColor">
            Financial Info
          </h3>
          {renderDetailItem(
            "Selling Price",
            `$${financialInfo?.sellingPrice?.toLocaleString()}`
          )}
        </div>

        {/* Utilities */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h3 className="text-xl font-semibold mb-4 text-primaryColor">
            Utilities
          </h3>
          {renderDetailItem("Water Access", utilities?.waterAccess)}
          {renderDetailItem("Sewage System", utilities?.sewageSystem)}
          {renderDetailItem(
            "Electricity Access",
            utilities?.electricityAccess ? "Yes" : "No"
          )}
        </div>
      </div>
    </div>
  );
};

export default LandsDetail;
