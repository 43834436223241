import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const UpdateBuilding = () => {
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const params = useParams();
    const [isloading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState();
    const [editLoading, setEditLoading] = useState(false)
    const navigate = useNavigate()

    const getBuildingData = () => {
        setIsLoading(true)
        axios.get(`https://arapti.onrender.com/real-estate/buildings/get-all-buildings`)
            .then((response) => {
                const filteredData = response?.data?.find((ele) => ele?._id === params?.id);
                setIsLoading(false)
                setFormData(filteredData);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            });
    };

    useEffect(() => {
        getBuildingData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // Splitting the name by dots to access nested properties
        const nameParts = name.split('.');

        // Creating a copy of formData to avoid mutating state directly
        let updatedFormData = { ...formData };

        // Iterating through the nested structure to update the correct property
        let currentLevel = updatedFormData;
        for (let i = 0; i < nameParts.length; i++) {
            // If it's the last part of the name, update the value
            if (i === nameParts.length - 1) {
                currentLevel[nameParts[i]] = value;
            } else {
                // If it's not the last part, continue to the next level
                currentLevel[nameParts[i]] = { ...currentLevel[nameParts[i]] };
                currentLevel = currentLevel[nameParts[i]];
            }
        }

        // Update the state with the modified formData
        setFormData(updatedFormData);
    };



    const handleSubmit = () => {
        setEditLoading(true);
        // console.log(formData);

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `https://arapti.onrender.com/real-estate/buildings/edit-building-by-id/${params?.id}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: formData
        };

        axios.request(config)
            .then((response) => {
                setEditLoading(false)
                // console.log(response?.data);
                navigate('/buildings')
                toast.success('Building Data Updated Successfully');
            })
            .catch((error) => {
                setEditLoading(false)
                console.log(error);
                toast.error('Something went wrong');
            });

    };

    const handleLocationBenefitChange = (e, index) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            generalInfo: {
                ...prevState.generalInfo,
                locationBenifits: prevState.generalInfo.locationBenifits.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            [name]: value
                        };
                    }
                    return item;
                })
            }
        }));
    };

    const addLocationBenefit = () => {
        setFormData(prevState => ({
            ...formData,
            generalInfo: {
                ...formData.generalInfo,
                locationBenifits: [...prevState.generalInfo.locationBenifits, { name: '', value: '' }]
            }
        }));
    };

    const removeLocationBenefit = index => {
        setFormData(prevState => ({
            ...formData,
            generalInfo: {
                ...formData.generalInfo,
                locationBenifits: prevState.generalInfo.locationBenifits.filter((_, i) => i !== index)
            }
        }));
    };

    const handleAddAmenity = () => {
        setFormData(prevState => ({
            ...prevState,
            extraInfo: {
                ...prevState.extraInfo,
                amenities: [...prevState.extraInfo.amenities, '']
            }
        }));
    };

    const handleRemoveAmenity = (index) => {
        setFormData(prevState => ({
            ...prevState,
            extraInfo: {
                ...prevState.extraInfo,
                amenities: prevState.extraInfo.amenities.filter((_, i) => i !== index)
            }
        }));
    };

    const handleAmenityChange = (e, index) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            extraInfo: {
                ...prevState.extraInfo,
                amenities: prevState.extraInfo.amenities.map((amenity, i) => i === index ? value : amenity)
            }
        }));
    };

    const handleUnitChange = (e, index, field) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            units: prevState.units.map((unit, i) => i === index ? { ...unit, [field]: value } : unit)
        }));
    };

    const addUnit = () => {
        setFormData(prevState => ({
            ...prevState,
            units: [...prevState.units, { unitType: '', numberOfBedrooms: '', numberOfBathrooms: '', numberOfKitchens: '', balcony: '', terrace: '' }]
        }));
    };

    const removeUnit = (index) => {
        setFormData(prevState => ({
            ...prevState,
            units: prevState.units.filter((_, i) => i !== index)
        }));
    };

    const handleDescriptionChange = (e, index) => {
        const { value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            propertyDescription: prevState.propertyDescription.map((description, i) =>
                i === index ? value : description
            )
        }));
    };

    // Function to add a new property description
    const addDescription = () => {
        setFormData(prevState => ({
            ...prevState,
            propertyDescription: [...prevState.propertyDescription, '']
        }));
    };

    // Function to remove a property description
    const removeDescription = (index) => {
        setFormData(prevState => ({
            ...prevState,
            propertyDescription: prevState.propertyDescription.filter((_, i) => i !== index)
        }));
    };

    // const handleImageGroupChange = async (e) => {
    //     const imageFile = e.target.files[0];
    //     setImageUploadLoading(true);
    //     try {
    //         const formDataCloudinary = new FormData();
    //         formDataCloudinary.append('file', imageFile);
    //         formDataCloudinary.append('upload_preset', 'Arapti Buildings');

    //         const cloudinaryResponse = await axios.post(
    //             `https://api.cloudinary.com/v1_1/dhysboq8w/image/upload`,
    //             formDataCloudinary

    //         );
    //         const cloudinaryUrl = cloudinaryResponse?.data?.secure_url;
    //         if (cloudinaryUrl) {
    //             setImageUploadLoading(false);
    //         }

    //         setFormData(prevState => ({
    //             ...prevState,
    //             BuildingsImages: [...prevState.BuildingsImages, cloudinaryUrl]
    //         }));

    //     } catch (error) {
    //         console.error('Error submitting form:', error);
    //         setImageUploadLoading(false);
    //     }
    // }
    const handleImageGroupChange = async (e) => {
        const imageFiles = e.target.files;
        setImageUploadLoading(true);

        try {
            const uploadPromises = Array.from(imageFiles).map(async (imageFile) => {
                const formDataCloudinary = new FormData();
                formDataCloudinary.append('file', imageFile);
                formDataCloudinary.append('upload_preset', 'Arapti Buildings');

                const cloudinaryResponse = await axios.post(
                    `https://api.cloudinary.com/v1_1/dhysboq8w/image/upload`,
                    formDataCloudinary
                );
                return cloudinaryResponse?.data?.secure_url;
            });

            const cloudinaryUrls = await Promise.all(uploadPromises);

            setImageUploadLoading(false);

            setFormData(prevState => ({
                ...prevState,
                BuildingsImages: [...prevState.BuildingsImages, ...cloudinaryUrls]
            }));

        } catch (error) {
            console.error('Error submitting form:', error);
            setImageUploadLoading(false);
        }
    };

    const handleImageChangeBuildingPlan = async (e) => {
        const imageFile = e.target.files[0];
        setImageUploadLoading(true);
        try {
            const formDataCloudinary = new FormData();
            formDataCloudinary.append('file', imageFile);
            formDataCloudinary.append('upload_preset', 'Arapti Buildings');

            const cloudinaryResponse = await axios.post(
                'https://api.cloudinary.com/v1_1/dhysboq8w/image/upload',
                formDataCloudinary

            );
            const cloudinaryUrl = cloudinaryResponse?.data?.secure_url;
            if (cloudinaryUrl) {
                setImageUploadLoading(false);
            }

            setFormData(prevState => ({
                ...prevState,
                BuildingsPlansImage: cloudinaryUrl
            }));

        } catch (error) {
            console.error('Error submitting form:', error);
            setImageUploadLoading(false);
        }
    }

    const handleRemoveImage = (index) => {
        setFormData(prevState => ({
            ...prevState,
            BuildingsImages: prevState.BuildingsImages.filter((_, i) => i !== index)
        }));
    };

    return (
        <div className='p-8'>
            <h1 className='text-center text-2xl'>Update Form</h1>
            {imageUploadLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
            )}

            {
                isloading ?
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                    </div>
                    :
                    <>
                        <div className="mb-6">
                            {/* General Info ------------------------------------- */}
                            <h1 className='text-2xl mb-2 text-blue-500'>General Info</h1>
                            {/* Building Name */}
                            <div className='flex flex-col'>
                                <label>Building Name</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    name="generalInfo.BuildingsName"
                                    defaultValue={formData?.generalInfo?.BuildingsName || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <label className="block text-gray-700 text-sm font-bold mb-2 mt-2">
                                    Developer Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Developer Name"
                                    name="generalInfo.developerName"
                                    value={formData?.generalInfo?.developerName || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Building Type
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="generalInfo.BuildingsType"
                                    value={formData?.generalInfo?.BuildingsType || ''}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Building Type</option>
                                    <option value="residential">Residential</option>
                                    <option value="commercial">Commercial</option>
                                    <option value="both">Both</option>
                                </select>
                            </div>
                            <div className='flex flex-col'>
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Current Status
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="generalInfo.currentStatus"
                                    value={formData?.generalInfo?.currentStatus}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Current Status</option>
                                    <option value="under construction">Under Construction</option>
                                    <option value="pre-lunch">Pre-lunch</option>
                                    <option value="completed">Completed</option>
                                    <option value="upcoming">Upcoming</option>
                                </select>
                            </div>
                            <div className='flex flex-col'>
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Approval
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="generalInfo.approval"
                                    value={formData?.generalInfo?.approval}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Approval</option>
                                    <option value="RERA">RERA</option>
                                    <option value="BDA">BDA</option>
                                    <option value="BMC">BMC</option>
                                    <option value="BDO">BDO</option>
                                </select>
                            </div>
                            <div className='flex flex-col'>
                                {/* Location Benefits */}
                                <label className="block text-blue-500 text-sm font-bold mt-3 mb-2">
                                    Location Benefits
                                </label>
                                {formData?.generalInfo?.locationBenifits?.map((locationBenefit, index) => (
                                    <div key={index} className='bg-gray-200 px-4 py-4 rounded-md mb-4'>
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Name
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder="Name"
                                            name="name"
                                            value={locationBenefit.name}
                                            onChange={e => handleLocationBenefitChange(e, index)}
                                        />
                                        <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                            Value
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder="Value"
                                            name="value"
                                            value={locationBenefit.value}
                                            onChange={e => handleLocationBenefitChange(e, index)}
                                        />
                                        {index > 0 && (
                                            <button
                                                className="mt-3 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                type="button" onClick={() => removeLocationBenefit(index)}>Remove</button>
                                        )}
                                    </div>
                                ))}
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" onClick={addLocationBenefit}>Add More Location Benefit</button>
                            </div>

                            {/* Location Details */}
                            <div className="mb-6 mt-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Locations Details</h1>
                                {/* Address */}
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Address
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Address"
                                    name="locationDetails.address"
                                    value={formData?.locationDetails?.address}
                                    onChange={handleChange}
                                />
                                {/* City */}
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    City
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="City"
                                    name="locationDetails.city"
                                    value={formData?.locationDetails?.city}
                                    onChange={handleChange}
                                />
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    State
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="State"
                                    name="locationDetails.state"
                                    value={formData?.locationDetails?.state}
                                    onChange={handleChange}
                                />
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Zip Code
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    placeholder="zipCode"
                                    name="locationDetails.zipCode"
                                    value={formData?.locationDetails?.zipCode}
                                    onChange={handleChange}
                                />
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Country
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="country"
                                    name="locationDetails.country"
                                    value={formData?.locationDetails?.country}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Physical Characteristics */}
                            <div className="mb-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Physical Characteristics</h1>
                                {/* Year of Possession */}
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Year of Possession
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    placeholder="Year of Possession"
                                    name="physicalCharacteristics.yearOfProssesion"
                                    value={formData?.physicalCharacteristics.yearOfProssesion}
                                    onChange={handleChange}
                                />
                                {/* Number of Towers */}
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Number of Towers
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    placeholder="Number of Towers"
                                    name="physicalCharacteristics.numberOfTowers"
                                    value={formData?.physicalCharacteristics.numberOfTowers}
                                    onChange={handleChange}
                                />
                                {/* Total Floors */}
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Total Floors
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    placeholder="Total Floors"
                                    name="physicalCharacteristics.totalFloors"
                                    value={formData?.physicalCharacteristics.totalFloors}
                                    onChange={handleChange}
                                />
                                {/* Total Area */}
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Total Area
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    placeholder="Total Area"
                                    name="physicalCharacteristics.totalArea"
                                    value={formData?.physicalCharacteristics.totalArea}
                                    onChange={handleChange}
                                />
                                {/* Parking Facilities */}
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Parking Facilities
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="physicalCharacteristics.parkingFacilities"
                                    value={formData?.physicalCharacteristics.parkingFacilities}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Parking Facilities</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>

                            {/* //finanicial */}
                            <div className="mb-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Financial Info</h1>
                                {/* Selling Price */}
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Selling Price
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    placeholder="Selling Price"
                                    name="financialInfo.sellingPrice"
                                    value={formData?.financialInfo?.sellingPrice}
                                    onChange={handleChange}
                                />
                                {/* Maintenance Fees */}
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Maintenance Fees
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="number"
                                    placeholder="Maintenance Fees"
                                    name="financialInfo.maintenanceFees"
                                    value={formData?.financialInfo?.maintenanceFees}
                                    onChange={handleChange}
                                />
                                {/* Pricing Status */}
                                <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                    Pricing Status
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="financialInfo.pricingStatus"
                                    value={formData?.financialInfo?.pricingStatus}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Pricing Status</option>
                                    <option value="on request">On Request</option>
                                    <option value="call for price">Call for Price</option>
                                    <option value="fixed price">Fixed Price</option>
                                </select>
                            </div>

                            {/* Add amenities */}
                            <div className="mb-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Amenities</h1>
                                {formData?.extraInfo?.amenities?.map((amenity, index) => (
                                    <div key={index} className="flex items-center mb-2">
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            placeholder="Amenity"
                                            value={amenity}
                                            onChange={(e) => handleAmenityChange(e, index)}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveAmenity(index)}
                                            className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={handleAddAmenity}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                >
                                    Add Amenity
                                </button>
                                <span className='ml-4 text-red-500'>{formData?.units?.length < 1 && "This is required so please Add Aminities"}</span>
                            </div>

                            {/* Furnishings */}
                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Furnishings
                                </label>
                                <select
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="extraInfo.furnishings"
                                    value={formData?.extraInfo?.furnishings}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Furnishings</option>
                                    <option value="fully furnished">Fully Furnished</option>
                                    <option value="semi-furnished">Semi-Furnished</option>
                                    <option value="not furnished">Not Furnished</option>
                                </select>
                            </div>

                            {/* Units */}
                            <div className="mb-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Units</h1>
                                {formData?.units?.map((unit, index) => (
                                    <div key={index} className='bg-gray-200 px-4 py-4 rounded-lg mb-4'>
                                        {/* Unit Type */}
                                        <label className="block text-gray-700 text-sm font-bold mb-2">
                                            Unit Type
                                        </label>
                                        <select
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            name={`units.${index}.unitType`}
                                            value={unit?.unitType}
                                            onChange={e => handleUnitChange(e, index, 'unitType')}
                                        >
                                            <option value="">Select Unit Type</option>
                                            <option value="1 BHK 1T">1 BHK 1T</option>
                                            <option value="2 BHK 2T">2 BHK 2T</option>
                                            <option value="3 BHK 2T">3 BHK 2T</option>
                                            <option value="3 BHK 3T">3 BHK 3T</option>
                                            <option value="4 BHK 4T">4 BHK 4T</option>
                                            <option value="4 BHK 3T">4 BHK 3T</option>
                                            <option value="Villa">Villa</option>
                                            <option value="Studio">Studio</option>
                                            <option value="Duplex">Duplex</option>
                                            <option value="Triplex">Triplex</option>
                                            <option value="Simplex">Simplex</option>
                                        </select>

                                        {/* Number of Bedrooms */}
                                        <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                            Number of Bedrooms
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number"
                                            name={`units.${index}.numberOfBedrooms`}
                                            placeholder="Number of Bedrooms"
                                            value={unit?.numberOfBedrooms}
                                            onChange={e => handleUnitChange(e, index, 'numberOfBedrooms')}
                                        />

                                        {/* Number of Bathrooms */}
                                        <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                            Number of Bathrooms
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number"
                                            placeholder="Number of Bathrooms"
                                            name={`units.${index}.numberOfBathrooms`}
                                            value={unit?.numberOfBathrooms}
                                            onChange={e => handleUnitChange(e, index, 'numberOfBathrooms')}
                                        />

                                        {/* Number of Kitchens */}
                                        <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                            Number of Kitchens
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number"
                                            placeholder="Number of Kitchens"
                                            name={`units.${index}.numberOfKitchens`}
                                            value={unit?.numberOfKitchens}
                                            onChange={e => handleUnitChange(e, index, 'numberOfKitchens')}
                                        />

                                        {/* Balcony */}
                                        <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                            Balcony
                                        </label>
                                        <select
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            value={unit.balcony}
                                            name={`units.${index}.balcony`}
                                            onChange={e => handleUnitChange(e, index, 'balcony')}
                                        >
                                            <option value="">Select Balcony</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>

                                        {/* Terrace */}
                                        <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
                                            Terrace
                                        </label>
                                        <select
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            value={unit?.terrace}
                                            name={`units.${index}.terrace`}
                                            onChange={e => handleUnitChange(e, index, 'terrace')}
                                        >
                                            <option value="">Select Terrace</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>

                                        {/* Remove Unit button */}
                                        {index > 0 && (
                                            <button
                                                className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                type="button" onClick={() => removeUnit(index)}>Remove Unit</button>
                                        )}
                                    </div>
                                ))}
                                {/* Add Unit button */}
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" onClick={addUnit}>Add More Units</button>
                            </div>

                            {/* Property Description */}
                            <div className="mb-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Property Description</h1>
                                {formData?.propertyDescription?.map((description, index) => (
                                    <div key={index} className='flex items-center gap-4 mb-2'>
                                        <input
                                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                            type="text"
                                            placeholder="Description"
                                            value={description}
                                            onChange={e => handleDescriptionChange(e, index)}
                                        />
                                        {index > 0 && (
                                            <button
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                type="button" onClick={() => removeDescription(index)}>Remove</button>
                                        )}
                                    </div>
                                ))}
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="button" onClick={addDescription}>{formData?.propertyDescription?.length === 0 ? "Add Description" : "Add More Description"}</button>
                            </div>

                            <div className="mb-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Buildings Plans Image</h1>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChangeBuildingPlan}
                                    multiple
                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                />
                                {
                                    formData?.BuildingsPlansImage !== '' &&
                                    <div className=' border p-2 mt-4 rounded'>
                                        <img src={formData?.BuildingsPlansImage} alt="Buildings" className='w-16 h-16' />
                                    </div>
                                }
                            </div>

                            <div className="mb-6">
                                <h1 className='text-2xl mb-2 text-blue-500'>Buildings Images</h1>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageGroupChange}
                                    multiple
                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                />

                                <div className={`${formData?.BuildingsImages?.length < 1 && 'hidden'} flex flex-wrap gap-4 mt-4 border p-2 rounded`}>
                                    {
                                        formData?.BuildingsImages.map((ele, index) => (
                                            <div key={index}>
                                                <img src={ele} alt="Buildings Images" className='w-16 h-16' />
                                                <button onClick={() => handleRemoveImage(index)} className='text-center w-full text-red-500'>Remove</button>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>

                        </div>

                        {/* Add other sections and submit button */}
                        <div className='flex items-center justify-center'>
                            <button
                                className="bg-blue-500 w-[50%] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={handleSubmit}
                            >
                                {editLoading ? "Loading..." : "Submit"}
                            </button>
                        </div>
                    </>
            }
        </div>
    )
}

export default UpdateBuilding;
