import React from 'react'
import ProjectsContainer from '../../components/Projects/ProjectsContainer'

import UserHeader from '../../components/common/UserHeader'
const Projects = () => {
  return (
    <React.Fragment>
      <UserHeader
        pageName="Our Projects"
        mainText="Home" />
      <ProjectsContainer />
    </React.Fragment>
  )
}

export default Projects