import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { IoBagAdd } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";

const AddProjectForm = () => {
  const { register, handleSubmit } = useForm();
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [amenities, setAmenities] = useState("");
  const [amenitiesArr, setAmenitiesArr] = useState([]);
  const [imgArr, setImgArr] = useState([]);
  const [load, setLoad] = useState(false);

  const handleImageGroupChange = async (e) => {
    const imageFiles = e.target.files;
    setImageUploadLoading(true);

    try {
      const uploadPromises = Array.from(imageFiles).map(async (imageFile) => {
        const formDataCloudinary = new FormData();
        formDataCloudinary.append("file", imageFile);
        formDataCloudinary.append("upload_preset", "Assavio Buildings");

        const cloudinaryResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/dhysboq8w/image/upload`,
          formDataCloudinary
        );
        return cloudinaryResponse?.data?.secure_url;
      });

      const cloudinaryUrls = await Promise.all(uploadPromises);

      setImageUploadLoading(false);
      setImgArr([...imgArr, ...cloudinaryUrls]);

      //   setFormData((prevState) => ({
      //     ...prevState,
      //     BuildingsImages: [...prevState.BuildingsImages, ...cloudinaryUrls],
      //   }));
    } catch (error) {
      console.error("Error submitting form:", error);
      setImageUploadLoading(false);
    }
  };
  console.log("---imGarr---->", imgArr);

  const handleAmenitesArr = () => {
    if (amenitiesArr.includes(amenities)) {
      toast.error("Amenity is already present !");
    } else if (amenities) {
      const newArr = [...amenitiesArr];
      newArr.push(amenities);
      setAmenitiesArr(newArr);
      setAmenities("");
    } else {
      toast.error("Please enter Amenities !");
    }
  };

  const removeAmenities = (index) => {
    const filterAmenities = amenitiesArr?.filter((eachItem, itemIndex) => {
      return itemIndex != index;
    });
    setAmenitiesArr(filterAmenities);
  };

  const handleFormSubmit = (data) => {

    setLoad(true);

    const myData = {
      ...data,
      amenities: amenitiesArr,
      images: imgArr,
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://arapti.onrender.com/real-estate/project/create_project",
      headers: {
        "Content-Type": "application/json",
      },
      data: myData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        document.getElementById("project_create").reset();
        toast.success("Project Created Successfully");
        setImgArr([]);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.massage);
        setLoad(false);
      });
  };

  const handleRemoveImage = (index) => {
    const filterArr = imgArr.filter((eachItem, itemIndex) => {
      return itemIndex != index;
    });
    setImgArr(filterArr);
  };

  return (
    <div className="p-2 m-2 ">
      <ToastContainer />
      {imageUploadLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      <h1 className="my-3 text-2xl font-semibold">Add Project</h1>
      <form
        id="project_create"
        onSubmit={handleSubmit(handleFormSubmit)}
        className="w-full mx-auto"
      >
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Project Name
          </label>
          <input
            type="text"
            name="projectName"
            {...register("projectName")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Project Type
          </label>
          <select
            name="projectType"
            {...register("projectType")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          >
            <option value="">Select Project Type</option>
            <option value="Land">Land</option>
            <option value="Duplex">Duplex</option>
            <option value="Triplex">Triplex</option>
            <option value="Simplex">Simplex</option>
            <option value="4+S">4+S</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Project Status
          </label>
          <select
            name="projectStatus"
            {...register("projectStatus")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          >
            <option value="">Select Status</option>
            <option value="Ongoing">Ongoing</option>
            <option value="Finished">Finished</option>
            <option value="Upcoming">Upcoming</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Parking Facility
          </label>
          <select
            name="parkingFacility"
            {...register("parkingFacility")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          >
            <option value="">Select Parking Facility</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="mb-4 flex gap-2 items-center justify-between">
          <div className="w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Amenities
            </label>
            <input
              type="text"
              name="amenities"
              // {...register("amenities")}

              value={amenities}
              onChange={(e) => {
                setAmenities(e.target.value);
              }}
              placeholder="Enter amenities, separated by commas"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            />
          </div>
          <IoBagAdd
            onClick={() => {
              handleAmenitesArr();
            }}
            className="mt-5 cursor-pointer"
            color="#1172b9"
            size={30}
          />
        </div>
        <div className="mt-2 rounded-lg bg-gray-50 px-2 py-2 ">
          <ul className="flex flex-wrap items-center gap-1">
            {amenitiesArr?.map((each, index) => {
              return (
                <li
                  key={index}
                  className="flex items-center gap-1 rounded-full bg-gray-500 px-2 py-1 text-sm text-white"
                >
                  {each.substring(0, 30)}
                  <IoCloseSharp
                    onClick={() => {
                      removeAmenities(index);
                    }}
                    size={20}
                    className="font-semibold cursor-pointer"
                  />
                </li>
              );
            })}
          </ul>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            name="description"
            {...register("description")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Location
          </label>
          <input
            type="text"
            name="location"
            {...register("location")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Year of Completion
          </label>
          <input
            type="number"
            name="yearOfCompletion"
            {...register("yearOfCompletion")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Price Type
          </label>
          <select
            name="priceType"
            {...register("priceType")}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            required
          >
            <option value="">Select Price Type</option>
            <option value="On Request">On Request</option>
            <option value="Call For Price">Call For Price</option>
            <option value="Fixed Price">Fixed Price</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Images
          </label>
          <input
            type="file"
            name="images"
            multiple
            onChange={handleImageGroupChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          />
        </div>
        <h1 className="tracking-wide text-[0.9rem] my-1 font-semibold">Add atleast 5 Images.</h1>
        <div
          className={`${imgArr?.length < 1 && "hidden"
            } flex flex-wrap gap-4 mt-4 border p-2 rounded my-3`}
        >
          {imgArr?.map((ele, index) => (
            <div key={index}>
              <img src={ele} alt="Building Images" className="w-16 h-16" />
              <button
                onClick={() => handleRemoveImage(index)}
                className="text-center w-full text-red-500 my-3"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="bg-[#1172b9] text-white py-2 w-[9rem] px-4 rounded tracking-wide"
        >
          {load ? "Adding..." : " Add Project"}
        </button>
      </form>
    </div>
  );
};

export default AddProjectForm;
