import img1 from "../../assets/about/car.png"; 
import img2 from "../../assets/about/pool.png";
import img3 from "../../assets/about/Security.png";
import img4 from "../../assets/about/Medical.png";
import img5 from "../../assets/about/Library.png";
import img6 from "../../assets/about/Beds.png";
import img7 from "../../assets/about/Homes.png";
import img8 from "../../assets/about/Playland.png";

const amenitiesData = [
  {
    id: 1,
    title: "Parking Space",
    description: "Convenient parking facility ensuring safe and spacious vehicle accommodation.",
    image: img1,
  },
  {
    id: 2,
    title: "Swimming Pool",
    description: "Relax and unwind in a luxurious pool area designed for leisure.",
    image: img2,
  },
  {
    id: 3,
    title: "Private Security",
    description: "24/7 professional security ensuring safety and privacy.",
    image: img3,
  },
  {
    id: 4,
    title: "Medical Center",
    description: "Immediate access to healthcare services and emergency support.",
    image: img4,
  },
  {
    id: 5,
    title: "Library Area",
    description: "Quiet and well-stocked space for reading and study.",
    image: img5,
  },
  {
    id: 6,
    title: "King Size Beds",
    description: "Spacious, comfortable beds providing a luxurious sleep experience.",
    image: img6,
  },
  {
    id: 7,
    title: "Smart Homes",
    description: "Advanced technology-enabled homes offering modern convenience and efficiency.",
    image: img7,
  },
  {
    id: 8,
    title: "Kid’s Playland",
    description: "Fun and secure play area designed for children’s entertainment.",
    image: img8,
  },
];

const Amenities = () => {
  return (
    <div className="px-4 sm:px-8 md:px-12 py-8 w-full">
      <div className="flex flex-col items-center justify-center mt-8 md:mt-12">
        <h1 className="font-semibold text-2xl sm:text-3xl md:text-4xl text-center">
          Property Amenities
        </h1>
        <p className="text-gray-500 text-sm sm:text-base mt-3 text-center max-w-3xl">
          Here are some common property amenities that can be highlighted for
          real estate listings or developments:
        </p>
      </div>
      <div className="mt-8 grid items-center justify-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 bg-[#fdf8f8] w-full">
        {amenitiesData.map(({ id, title, description, image }) => (
          <div
            key={id}
            className="bg-white shadow-lg rounded-lg p-4 sm:p-6 flex flex-col items-start relative transition-transform transform hover:scale-105"
          >
            <div className="relative w-full mb-4 flex items-center justify-center">
              <div className="bg-white rounded-full shadow-xl flex items-center justify-center w-[50px] h-[50px] md:w-[4rem] md:h-[4rem] absolute top-0 right-0">
                <img
                  src={image}
                  alt={title}
                  className="object-contain h-8 w-8 md:h-10 md:w-10"
                />
              </div>
            </div>
            <div className="items-start flex flex-col">
              <span className="text-blue-500 text-lg sm:text-xl font-bold mt-16 sm:mt-20 md:mt-24">{`0${id}`}</span>
              <h3 className="font-semibold text-lg sm:text-xl text-center mt-2">
                {title}
              </h3>
            </div>
            <p className="text-gray-400 text-xs sm:text-sm mt-2 text-start">
              {description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Amenities;
