import React from "react";
import member1 from "../../assets/about/Generalmanager.jpeg";
import member2 from "../../assets/about/marketing.jpeg";
import member3 from "../../assets/about/marketingexecutie.jpeg";
import member4 from "../../assets/about/Senioraccountant.jpeg";
import member5 from "../../assets/about/Market_Exicutive.jpeg";
import member7 from "../../assets/about/Driver.jpeg";
import member6 from "../../assets/about/Reception.jpeg";
const members = [
  {
    id: 1,
    name: "Dhanjay Barik",
    role: "General Manager",
    image: member3,
  },
  {
    id: 2,
    name: "Monalisa Samal",
    role: "Senior Accountant",
    image: member1,
  },

  {
    id: 3,
    name: "Laxminarayan Pradhan",
    role: "AGM / Marketing Executives",
    image: member2,
  },

  {
    id: 4,
    name: "Amit Kumar Dash",
    role: "Project General Manager",
    image: member4,
  },
  {
    id: 5,
    name: "Lelin kumar Nayak",
    role: "Marketing Executives",
    image: member5,
  },
  {
    id: 6,
    name: "Sasmita Sahoo",
    role: "Reception/ Telecaller",
    image: member6,
  },
  {
    id: 7,
    name: "Nihar Ranjan Dash",
    role: "Office boy/Driver",
    image: member7,
  },
];

const TeamMember = () => {
  return (
    <div className="px-10 py-8 w-full ">
      <div className="flex flex-col items-center justify-center">
        <h1 className="font-semibold text-3xl md:text-4xl tracking-wide text-center">
          Team Members
        </h1>
        <p className="text-gray-400 text-sm mt-3 text-center mx-auto max-w-3xl tracking-wide">
          "Meet our dedicated team members who bring expertise, passion, and
          innovation to every project. Together, we work collaboratively to
          achieve excellence, ensuring success and satisfaction for our clients
          and partners."
        </p>
      </div>
      <div className="mt-6 flex flex-wrap items-center justify-center gap-8">
        {members.map(({ id, name, role, image }) => (
          <div
            key={id}
            className="bg-[#f8f7fc] rounded-lg overflow-hidden shadow w-full sm:w-[48%] md:w-[30%] lg:w-[22%] flex-shrink-0"
          >
            <img
              src={image}
              alt={name}
              className="rounded-lg w-full h-[20rem] sm:h-[22rem] md:h-[25rem] lg:h-[28rem] object-cover"
            />
            <div className="p-4 text-center">
              <h3 className="font-semibold text-lg">{name}</h3>
              <p className="text-xl text-gray-500 font-serif">{role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMember;
