import React from "react";
import twohome from "../../assets/about/Frame.png";

const Ourmission = () => {
  return (
    <>
      <div className="flex flex-col sm:flex-row items-center justify-center w-full">
        <div className="w-full sm:w-1/2 lg:w-2/5 mb-8 sm:mb-0">
          <img src={twohome} alt="Two Homes" className="w-[90%] h-auto mx-auto" />
        </div>
        <div className="flex flex-col space-y-8 sm:space-y-12 w-full sm:w-1/2 justify-center px-4 sm:px-8 lg:px-14">
          <div className="bg-[#f8f7fc] p-6 md:p-8 rounded-lg">
            <h2 className="font-bold text-xl sm:text-2xl mb-4 tracking-wider text-center sm:text-left">
              Our Mission
            </h2>
            <p className="text-gray-400 text-sm sm:text-base mt-3 text-center sm:text-left max-w-3xl tracking-wide">
              To be first choice for our consumers and associates in their journey of discovering, buying, selling, and financing property. We are doing that with proper data, design, technology, and above all the passion of our stakeholder.
            </p>
          </div>
          <div className="bg-[#f8f7fc] p-6 md:p-8 rounded-lg">
            <h2 className="font-bold text-xl sm:text-2xl mb-4 tracking-wider text-center sm:text-left">
              Our Vision
            </h2>
            <p className="text-gray-400 text-sm sm:text-base mt-3 text-center sm:text-left tracking-wide">
              In 2024 the real estate industry transparency & professionalism. Our vision is to bring these values into ARAPTI.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ourmission;
