import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoChevronBackCircleSharp } from "react-icons/io5";

const EditLandForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentLandDetails, setCurrentLandDetails] = useState({});
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  console.log(id);
  const getLandDetailsById = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://arapti.onrender.com/real-estate/lands/get-land-by-id/${id}`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("i am response data----->", response.data);
        setCurrentLandDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFieldChange = (category, field, value) => {
    setCurrentLandDetails((prev) => {
      return {
        ...prev,
        [category]: {
          ...prev[category],
          [field]: value,
        },
      };
    });
  };

  const handleRemoveImage = (index) => {
    setCurrentLandDetails((prevState) => ({
      ...prevState,
      propertyImages: prevState.propertyImages.filter((_, i) => i !== index),
    }));
  };

  const handleImageGroupChange = async (e) => {
    const imageFiles = e.target.files;
    setImageUploadLoading(true);

    try {
      const uploadPromises = Array.from(imageFiles).map(async (imageFile) => {
        const formDataCloudinary = new FormData();
        formDataCloudinary.append("file", imageFile);
        formDataCloudinary.append("upload_preset", "Assavio Buildings");

        const cloudinaryResponse = await axios.post(
          `https://api.cloudinary.com/v1_1/dhysboq8w/image/upload`,
          formDataCloudinary
        );
        return cloudinaryResponse?.data?.secure_url;
      });

      const cloudinaryUrls = await Promise.all(uploadPromises);

      setCurrentLandDetails((prevState) => ({
        ...prevState,
        propertyImages: [...prevState.propertyImages, ...cloudinaryUrls],
      }));
      setImageUploadLoading(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      setImageUploadLoading(false);
    }
  };

  const handleUpdateDetails = (e) => {
    e.preventDefault();
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `https://arapti.onrender.com/real-estate/lands/edit-land-by-id/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: currentLandDetails,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        toast.success("Land Details Updated Successfully.");
        setTimeout(() => {
          navigate("/lands");
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Couldn't Update land Details !");
      });
  };

  useEffect(() => {
    getLandDetailsById();
  }, []);
  return (
    <div className="container mx-auto px-6">
      <ToastContainer />
      {imageUploadLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      <div className="flex justify-between items-center px-4 mt-4">
        <h2 className="text-2xl font-normal mb-4">Edit Land Details</h2>
        <IoChevronBackCircleSharp
          onClick={() => {
            navigate("/lands");
          }}
          size={30}
          color="#1172b9"
          className="cursor-pointer"
        />
      </div>
      <form
        onSubmit={handleUpdateDetails}
        className="w-full border p-6 h-[85vh] overflow-y-scroll rounded-xl"
      >
        {/* General Info */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">General Info</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Property Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Property Name"
            name="generalInfo.propertyName"
            value={currentLandDetails?.generalInfo?.propertyName}
            onChange={(e) => {
              handleFieldChange("generalInfo", "propertyName", e.target.value);
            }}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Property Type
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="generalInfo.propertyType"
            value={currentLandDetails?.generalInfo?.propertyType}
            onChange={(e) => {
              handleFieldChange("generalInfo", "propertyType", e.target.value);
            }}
            required
          >
            <option value="" disabled>
              Select Property Type
            </option>
            <option value="agricultural">Agricultural</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="industrial">Industrial</option>
          </select>
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Current Status
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="generalInfo.currentStatus"
            value={currentLandDetails?.generalInfo?.currentStatus}
            onChange={(e) => {
              handleFieldChange("generalInfo", "currentStatus", e.target.value);
            }}
            required
          >
            <option value="" disabled>
              Select Current Status
            </option>
            <option value="available">Available</option>
            <option value="under contract">Under Contract</option>
            <option value="sold">Sold</option>
          </select>
        </div>

        {/* Location Details */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Location Details</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Address
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Address"
            name="locationDetails.address"
            value={currentLandDetails?.locationDetails?.address}
            onChange={(e) => {
              handleFieldChange("locationDetails", "address", e.target.value);
            }}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            City
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="City"
            name="locationDetails.city"
            value={currentLandDetails?.locationDetails?.city}
            onChange={(e) => {
              handleFieldChange("locationDetails", "city", e.target.value);
            }}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            State
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="State"
            name="locationDetails.state"
            value={currentLandDetails?.locationDetails?.state}
            onChange={(e) => {
              handleFieldChange("locationDetails", "state", e.target.value);
            }}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Zip Code
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Zip Code"
            name="locationDetails.zipCode"
            value={currentLandDetails?.locationDetails?.zipCode}
            onChange={(e) => {
              handleFieldChange("locationDetails", "zipCode", e.target.value);
            }}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Country
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Country"
            name="locationDetails.country"
            value={currentLandDetails?.locationDetails?.country}
            onChange={(e) => {
              handleFieldChange("locationDetails", "country", e.target.value);
            }}
            required
          />
        </div>

        {/* Physical Characteristics */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">
            Physical Characteristics
          </h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Total Area
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Total Area (sq ft)"
            name="physicalCharacteristics.totalArea"
            value={currentLandDetails?.physicalCharacteristics?.totalArea}
            onChange={(e) => {
              handleFieldChange(
                "physicalCharacteristics",
                "totalArea",
                e.target.value
              );
            }}
            required
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Terrain Type
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="physicalCharacteristics.terrainType"
            value={currentLandDetails?.physicalCharacteristics?.terrainType}
            onChange={(e) => {
              handleFieldChange(
                "physicalCharacteristics",
                "terrainType",
                e.target.value
              );
            }}
            required
          >
            <option value="" disabled>
              Select Terrain Type
            </option>
            <option value="flat">Flat</option>
            <option value="hilly">Hilly</option>
            <option value="wooded">Wooded</option>
            <option value="cleared">Cleared</option>
          </select>
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Soil Type
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Soil Type"
            name="physicalCharacteristics.soilType"
            value={currentLandDetails?.physicalCharacteristics?.soilType}
            onChange={(e) => {
              handleFieldChange(
                "physicalCharacteristics",
                "soilType",
                e.target.value
              );
            }}
          />
          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Road Access
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="physicalCharacteristics.roadAccess"
            value={currentLandDetails?.physicalCharacteristics?.roadAccess}
            onChange={(e) => {
              handleFieldChange(
                "physicalCharacteristics",
                "roadAccess",
                e.target.value
              );
            }}
          >
            <option value="" disabled>
              Select Road Access
            </option>
            <option value="paved">Paved</option>
            <option value="dirt">Dirt</option>
            <option value="no access">No Access</option>
          </select>
        </div>

        {/* Financial Info */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Financial Info</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Selling Price
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="number"
            placeholder="Selling Price (₹)"
            name="financialInfo.sellingPrice"
            value={currentLandDetails?.financialInfo?.sellingPrice}
            onChange={(e) => {
              handleFieldChange(
                "financialInfo",
                "sellingPrice",
                e.target.value
              );
            }}
            required
          />
        </div>

        {/* Utilities */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Utilities</h1>

          <label className="block text-gray-700 text-sm font-bold mb-2">
            Water Access
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="utilities.waterAccess"
            value={currentLandDetails?.utilities?.waterAccess}
            onChange={(e) => {
              handleFieldChange("utilities", "waterAccess", e.target.value);
            }}
          >
            <option value="" disabled>
              Select Water Access
            </option>
            <option value="municipal">Municipal</option>
            <option value="well">Well</option>
            <option value="none">None</option>
          </select>

          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Sewage System
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="utilities.sewageSystem"
            value={currentLandDetails?.utilities?.sewageSystem}
            onChange={(e) => {
              handleFieldChange("utilities", "sewageSystem", e.target.value);
            }}
          >
            <option value="" disabled>
              Select Sewage System
            </option>
            <option value="municipal">Municipal</option>
            <option value="septic">Septic</option>
            <option value="none">None</option>
          </select>

          <label className="block text-gray-700 text-sm font-bold mt-2 mb-2">
            Electricity Access
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="utilities.electricityAccess"
            value={currentLandDetails?.utilities?.electricityAccess}
            onChange={(e) => {
              handleFieldChange(
                "utilities",
                "electricityAccess",
                e.target.value
              );
            }}
          >
            <option value="" disabled>
              Select Electricity Access
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        {/* image section */}
        <div className="mb-6">
          <h1 className="text-2xl mb-2 text-blue-500">Property Images</h1>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Upload Images
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="file"
            name="propertyImages"
            multiple
            onChange={handleImageGroupChange}
            accept="image/*"
          />
        </div>
        <div
          className={`${
            currentLandDetails?.propertyImages?.length < 1 && "hidden"
          } flex flex-wrap gap-4 mt-4 border p-2 rounded my-3`}
        >
          {currentLandDetails?.propertyImages?.map((ele, index) => (
            <div key={index}>
              <img src={ele} alt="Building Images" className="w-16 h-16" />
              <button
                onClick={() => handleRemoveImage(index)}
                className="text-center w-full text-red-500 my-3"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Update Property
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditLandForm;
