import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img from '../../assets/home/contact-property-thumb2.png.png';
import { FaHome } from 'react-icons/fa';

const PropertyDetails = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, 
            easing: 'ease-in-out', 
            once: true, 
            mirror: false,
        });
    }, []);

    return (
        <React.Fragment>
            <div className='flex flex-col items-center justify-center gap-y-4 sm:gap-y-6 md:gap-y-8 mt-5'>
                <p 
                    className="text-[#0B68F2] text-[15px] sm:text-[16px] md:text-[17px] flex items-center space-x-3"
                    data-aos="fade-down" 
                >
                    <FaHome />
                    <span>TRUSTED REAL ESTATE CARE</span>
                </p>
                <h1 
                    className="text-primaryTextColor text-[30px] sm:text-[35px] md:text-[45px] font-bold text-center"
                    data-aos="fade-up"
                >
                    Looking For Buying Property
                </h1>
            </div>
            <div 
                className="flex flex-col lg:flex-row items-center justify-center px-4 md:px-6 lg:px-10 rounded-lg w-full mx-auto mt-6 lg:mt-10"
                data-aos="fade-up"
            >
                {/* Property Details Section */}
                <div className="space-y-4 lg:w-[50%] p-6 flex flex-col justify-between">
                    <div data-aos="fade-right">
                        <h2 className="text-xl sm:text-2xl font-semibold">
                            Browse our property listing and find your best
                        </h2>
                        <ul className="list-disc pl-5 text-gray-700 mt-4">
                            <li>Living rooms are pre-wired for Surround</li>
                            <li>Luxurious interior design and amenities</li>
                            <li>Nestled in the Buckhead</li>
                        </ul>
                    </div>
                    <div className="mt-4" data-aos="fade-left">
                        <img
                            src={img}
                            alt="Property"
                            className="w-full h-auto rounded-lg shadow-md object-cover"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PropertyDetails;
