import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectsCard = ({ data }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true
    };
    return (
        <React.Fragment>
            <div
                className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col md:flex-row md:h-[22rem]">
                <div className="w-full md:w-[45%]">
                    <div className=" w-full">
                        <Slider {...settings}>
                            {data.images.map((image, index) => (
                                <div key={index}>
                                    <img
                                        src={image}
                                        alt={`Slide ${index}`}
                                        style={{ width: "100%" }}
                                        className="object-cover h-48 w-full md:h-[22rem]"
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

                <div className="p-4 md:p-6 flex-1 flex flex-col space-y-3">
                    <div className='flex justify-between'>
                        <p className='tracking-wide'>
                            Projectname
                        </p>
                        <p className='text-gray-400'>{data.projectName}</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='tracking-wide'>
                            Projectstatus
                        </p>
                        <p className='text-gray-400'>
                            {data.projectStatus}
                        </p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='tracking-wide'>
                            ParkingFacility
                        </p>
                        <p className='text-gray-400'>
                            {data.parkingFacility}
                        </p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='tracking-wide'>
                            Yearcomplete
                        </p>
                        <p className='text-gray-400'>
                            {data.yearOfCompletion}
                        </p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='tracking-wide'>
                            Amenities
                        </p>
                        <p className='text-gray-400 flex space-x-4'>
                            {
                                data.amenities[1]
                            }
                        </p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='tracking-wide'>
                            Price
                        </p>
                        <p className='text-gray-400'>
                            {
                                data.priceType
                            }
                        </p>
                    </div>
                    <div className='flex items-center space-x-3'>
                        <FaLocationDot />
                        <p>
                            {data.location}
                            {/* At/P.O-Kuhunda, Via-Kuanpal */}
                        </p>
                    </div>
                    <div>
                        <p className='tracking-wide'>
                            {data.description}
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProjectsCard