import React from 'react';
import { FaHeart } from 'react-icons/fa';
import { MdOutlineShareLocation } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { FaBuildingFlag } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const Card = ({ property }) => {
    const navigate = useNavigate();
    // console.log('property', property);

    return (
        <React.Fragment>
            <div
                onClick={() => navigate(`/property/${property?.generalInfo?.BuildingsName}/${property?._id}`)}
                className="bg-white cursor-pointer rounded-xl shadow-lg overflow-hidden flex flex-col md:flex-row">
                <div className="relative w-full md:w-1/3">
                    <img
                        src={property.BuildingsImages[0]}
                        alt='imgs'
                        className="object-cover h-48 w-full md:h-full" />
                    <div className="absolute top-4 left-4 bg-white text-gray-800 px-2 rounded-full font-medium text-sm">
                        For sale
                    </div>

                    <div className="absolute bottom-4 left-4">
                        <img
                            src={property?.BuildingsImages[1]}
                            alt="property-img"
                            className="w-10 h-10 rounded-full border-2 border-white" />
                    </div>
                </div>

                <div className="p-4 md:p-6 flex-1 flex flex-col">
                    <div className="flex justify-between items-start mb-4">
                        <h3 className="text-lg md:text-xl font-semibold tracking-wide">
                            {property?.generalInfo?.BuildingsName}
                        </h3>
                        <button className="text-[#E0E2E9] border border-[#D9D9D9] rounded-sm p-2">
                            <FaHeart />
                        </button>
                    </div>
                    <p className="text-blue-500 text-md font-bold mb-2 tracking-wide">
                        Call For Price
                    </p>

                    <div className="flex flex-wrap gap-4 mb-4 text-gray-600 text-sm tracking-wide">
                        <div className="flex items-center capitalize">
                            <FaBuildingFlag size={18} className="mr-2" />
                            {property?.generalInfo?.currentStatus}
                        </div>
                        <div className="flex items-center">
                            <BsFillPatchCheckFill size={18} className="mr-2" />
                            {property?.generalInfo?.approval}
                        </div>
                        <div className="flex items-center">
                            <MdOutlineShareLocation size={22} className="mr-2" />
                            {property?.physicalCharacteristics?.totalArea} Acre
                        </div>
                    </div>

                    <div className="flex items-center text-gray-600 text-sm tracking-wide">
                        <ImLocation2 size={18} className="mr-2" />
                        {property?.locationDetails?.address}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Card;
