import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { IoIosArrowDown } from 'react-icons/io';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RiArrowDownSFill } from 'react-icons/ri';
import Logo from '../../assets/logos/mainLogo.png';

const BottomNavbar = () => {
    const [activeMenuItem, setActiveMenuItem] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();  // Hook to get the current path
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
                setActiveMenuItem(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdownOptions = (menuItem) => {
        setActiveMenuItem(activeMenuItem === menuItem ? null : menuItem);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleItemClick = (path) => {
        navigate(path);
        setMenuOpen(false); // Close the menu after item click
    };

    const menuItems = [
        { title: 'Home', path: '/' },
        { title: 'About Us', path: '/about' },
        { title: 'Properties', path: '/properties' },
        { title: 'Projects', path: '/Projects' },
        { title: 'Career', path: '/career' },
        { title: 'Testimonials', path: '/testimonials' },
        { title: 'Contacts', path: '/contact' },
    ];

    return (
        <div>
            {/* Desktop Navbar */}
            <div className='bg-white md:px-10 px-6 py-3 xl:flex hidden items-center justify-between shadow-md'>
                <img
                    onClick={() => navigate('/')}
                    src={Logo}
                    alt="Logo"
                    className='w-48 h-auto cursor-pointer'
                />
                <div className='flex gap-8 items-center'>
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            className={`flex items-center gap-1 cursor-pointer relative 
                                ${location.pathname === item.path ? 'text-primaryBtnColor' : 'text-blue-950'} 
                                hover:text-primaryBtnColor font-semibold tracking-wide`}
                            onClick={() =>
                                item.options ? toggleDropdownOptions(item.title) : handleItemClick(item.path)
                            }
                        >
                            {item.title}
                            {item.options && <IoIosArrowDown size={20} className='mt-1' />}
                            <AnimatePresence>
                                {activeMenuItem === item.title && item.options && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        ref={menuRef}
                                        className="absolute bg-white z-40 top-[40px] left-0 shadow-lg rounded-b-xl overflow-hidden w-[200px]"
                                    >
                                        {item.options.map((option, idx) => (
                                            <motion.div
                                                key={idx}
                                                onClick={() => handleItemClick(option.path)}
                                            >
                                                <span className="px-8 py-2 cursor-pointer">
                                                    {option.title}
                                                </span>
                                            </motion.div>
                                        ))}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    ))}
                    <button
                        onClick={() => {
                            navigate('/login');
                            window.scrollTo(0, 0);
                        }}
                        className='bg-primaryBtnHoverColor hover:bg-primaryBtnColor text-white px-8 py-2 rounded-md text-sm'
                    >
                        Login
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            <div className='bg-white px-10 py-3 xl:hidden flex items-center justify-between shadow-lg'>
                <img
                    onClick={() => navigate('/')}
                    src={Logo}
                    alt="Logo"
                    className='w-32 h-auto cursor-pointer'
                />
                <div onClick={toggleMenu}>
                    <GiHamburgerMenu size={30} />
                </div>
            </div>
            <AnimatePresence>
                {menuOpen && (
                    <div className='fixed inset-0 bg-transparent flex justify-end z-50'>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.9, x: 100 }}
                            animate={{ opacity: 1, scale: 1, x: 0 }}
                            exit={{ opacity: 0, scale: 0.9, x: 100 }}
                            transition={{ duration: 0.2 }}
                            ref={menuRef}
                            className='bg-white shadow-lg text-black p-5 w-[60%] flex flex-col justify-between'
                        >
                            <div>
                                {menuItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`flex flex-col items-start gap-1 relative cursor-pointer 
                                            ${location.pathname === item.path ? 'text-primaryBtnColor' : ''}
                                            hover:text-primaryBtnColor`}
                                        onClick={() => (item.options ? toggleDropdownOptions(item.title) : handleItemClick(item.path))}
                                    >
                                        <div className='flex items-center mb-3 text-[20px]'>
                                            {item.title}
                                            {item.options && <RiArrowDownSFill size={20} />}
                                        </div>
                                        {activeMenuItem === item.title && item.options && (
                                            <motion.div
                                                initial={{ opacity: 0, scale: 0.9, y: -10 }}
                                                animate={{ opacity: 1, scale: 1, y: 0 }}
                                                exit={{ opacity: 0, scale: 0.9, y: -10 }}
                                                transition={{ duration: 0.2 }}
                                                className='bg-gray-100 text-black shadow-lg overflow-hidden w-full mb-4'
                                                style={{ zIndex: 9999, position: 'relative' }}
                                            >
                                                {item.options.map((option, idx) => (
                                                    <motion.div
                                                        key={idx}
                                                        whileHover={{ backgroundColor: '#FF1616', color: 'white' }}
                                                        className='px-8 py-2 text-[18px] transition duration-200 ease-linear cursor-pointer'
                                                        onClick={() => handleItemClick(option.path)}
                                                    >
                                                        {option.title}
                                                    </motion.div>
                                                ))}
                                            </motion.div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-between items-center'>
                                <button
                                    className='bg-primaryColor text-white px-4 py-2 rounded-md'
                                    onClick={() => {
                                        navigate('/login');
                                        setMenuOpen(false); // Close the menu after clicking "Login"
                                    }}
                                >
                                    Login
                                </button>
                                <button
                                    className='bg-gray-400 text-white px-4 py-2 rounded-md'
                                    onClick={() => setMenuOpen(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </motion.div>
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default BottomNavbar;
