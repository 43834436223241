/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import img1 from '../../assets/carouselImg1.png';
import img2 from '../../assets/carsImg2.jpeg';
import img3 from '../../assets/carsImg3.jpeg';
import img4 from '../../assets/carsImg4.jpeg';
import img5 from '../../assets/carsImg5.jpeg';
import img6 from '../../assets/carsImg6.jpeg';
import './Carousel.css';

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [currentIndex]);

    const images = [img1, img2, img3, img4, img5, img6];

    return (
        <React.Fragment>
            <div className="relative w-full overflow-hidden">
                <div
                    className="flex transition-transform duration-300 ease-in-out transform"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="w-full flex-shrink-0"
                        >
                            <img
                                src={image}
                                alt={`Slide ${index + 1}`}
                                className="w-full h-auto object-cover transition-transform duration-1000 ease-in-out scale-zoom"  
                            />
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Carousel;